import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { line } from 'd3-shape';
import { useTooltip } from '@nivo/tooltip';
import { curveFromProp } from '@nivo/core';
import { customValueLabel } from '../formatCharts/handleCustomValue';

const ExtraLine = ({
  bars, yScale, innerHeight, tooltip, styleConfig,
}) => {
  const tip = useTooltip();

  const lineGenerator = line()
    .x((bar) => (bar.x + bar.width / 2))
    .y((bar) => yScale(bar.data.data.lineAbsolute))
    .curve(curveFromProp(styleConfig.LineFormatControl));

  const handleTooltip = (e, datum) => (
    tip.showTooltipFromEvent(tooltip({ ...datum.data, color: datum.color }), e)
  );

  return (
    <>
      <path
        d={lineGenerator(bars)}
        fill="none"
        stroke={styleConfig.LineColorControl}
        strokeWidth={styleConfig.LineWidthControl}
        style={{ pointerEvents: 'none' }}
      />
      {bars.map((bar, i) => (
        <Fragment key={bar.key}>
          <g>
            {styleConfig.EnablePointsControl && (
              <circle
                cx={bar.x + bar.width / 2}
                cy={yScale(bar.data.data.lineAbsolute)}
                r={styleConfig.PointsSizeControl / 2}
                stroke={styleConfig.LineColorControl}
                strokeWidth={styleConfig.PointsBorderWidthControl}
                fill={styleConfig.PointsColorControl}
                style={{ pointerEvents: 'none' }}
              />
            )}
            {styleConfig.EnableLabelPointsControl
            && !(styleConfig.EnableLabelControl.checked && i === 0)
            && (
              <text
                dx={bar.x + bar.width / 2}
                dy={yScale(bar.data.data.lineAbsolute)}
                fill={styleConfig.LabelTextColorPointsControl}
                fillOpacity="1"
                dominantBaseline="central"
                textAnchor="middle"
                style={{
                  fontSize: `${styleConfig.LabelsFontSizeControl.value}px`,
                  fontFamily: `'${styleConfig.LabelsFontFamilyControl.value}', Arial`,
                  transform: `translate(${styleConfig.LabelTranslateXPoints}px, ${styleConfig.LabelTranslateYPoints}px) rotate(${styleConfig.LabelRotationPointsControl}deg)`,
                  transformOrigin: 'center center',
                  transformBox: 'fill-box',
                  outlineWidth: '0px',
                  outlineColor: 'transparent',
                  pointerEvents: 'none',
                }}
              >
                <tspan y={-15}>{customValueLabel(bar.data.data.lineAbsolute, styleConfig)}</tspan>
              </text>
            )}
          </g>
          <rect
            x={bar.x}
            y={0}
            height={innerHeight}
            width={bar.width}
            fill="transparent"
            onMouseEnter={(e) => handleTooltip(e, bar)}
            onMouseMove={(e) => handleTooltip(e, bar)}
            onMouseLeave={tip.hideTooltip}
          />
        </Fragment>
      )) }
    </>
  );
};

ExtraLine.propTypes = {
  bars: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  yScale: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  tooltip: PropTypes.func.isRequired,
  styleConfig: PropTypes.shape({
    LineFormatControl: PropTypes.string,
    LineColorControl: PropTypes.string,
    LineWidthControl: PropTypes.number,
    EnablePointsControl: PropTypes.bool,
    PointsSizeControl: PropTypes.number,
    PointsBorderWidthControl: PropTypes.number,
    PointsColorControl: PropTypes.string,
    EnableLabelPointsControl: PropTypes.bool,
    LabelsFontSizeControl: PropTypes.shape({ value: PropTypes.number }),
    LabelsFontFamilyControl: PropTypes.string,
    LabelTranslateXPoints: PropTypes.number,
    LabelTranslateYPoints: PropTypes.number,
    LabelRotationPointsControl: PropTypes.number,
    LabelTextColorPointsControl: PropTypes.string,
    EnableLabelControl: PropTypes.shape({ checked: PropTypes.bool }),
  }).isRequired,
};

export default ExtraLine;
