/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import fontOptions from '../utils/controllersUtils/options';
import getDefaultStyle from '../utils/chartTools/getDefaultStyle';

import ControllersGroup from './ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  ButtonLineController,
  SelectController,
  // RangeController,
  // ChoiceBoxController,
} from './Base';

import { Controllers as TableControllers } from '../charts/Table';
import { Controllers as ValueControllers } from '../charts/Value';
import { Controllers as BarControllers } from '../charts/Bar';
import { Controllers as LineControllers } from '../charts/Line';
import { Controllers as PieControllers } from '../charts/Pie';
import { Controllers as RadarControllers } from '../charts/Radar';
import { Controllers as MapControllers } from '../charts/Map';
import { Controllers as FunnelControllers } from '../charts/Funnel';
import { Controllers as RadialBarControllers } from '../charts/RadialBar';
import { Controllers as CalendarControllers } from '../charts/Calendar';
import { Controllers as WaffleControllers } from '../charts/Waffle';
import { Controllers as BoxPlotControllers } from '../charts/BoxPlot';
import { Controllers as ScatterPlotControllers } from '../charts/ScatterPlot';
import { Controllers as SubsetTableControllers } from '../charts/SubsetTable';
import { Controllers as ParetoControllers } from '../charts/Pareto';

const controllersGallery = {
  Bar: BarControllers,
  Pie: PieControllers,
  Line: LineControllers,
  Value: ValueControllers,
  Radar: RadarControllers,
  Map: MapControllers,
  Funnel: FunnelControllers,
  RadialBar: RadialBarControllers,
  Table: TableControllers,
  Calendar: CalendarControllers,
  Waffle: WaffleControllers,
  BoxPlot: BoxPlotControllers,
  ScatterPlot: ScatterPlotControllers,
  SubsetTable: SubsetTableControllers,
  Pareto: ParetoControllers,
};

const getControllersByType = (type) => (controllersGallery[type] || null);

const CustomSide = ({
  type,
  handle,
  config,
  data,
  selectedLine,
  selectedColumn,
  selectedValue,
  selectedFunction,
  isGlobalThemeActive,
}) => {
  const [extend, setExtend] = useState('title');

  useEffect(() => {
    setExtend('title');
  }, [type]);

  const style = useMemo(() => (
    { ...getDefaultStyle(config?.type || type), ...config }
  ), [config, type]);

  const KpiControllers = getControllersByType(style.type);

  return (data && style ? (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      borderRadius: '10px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      gap: '2px',
    }}
    >
      <ControllersGroup title="Título" id="title" extend={extend} setExtend={setExtend}>
        <InputLineController
          label="Título do KPI"
          handle={handle}
          configValue={style?.TitleControl}
          kWord="TitleControl"
          fullWidth
        />
        <ToggleSwitchController
          label="Mostrar Título"
          handle={handle}
          kWord="ShowTitleControl"
          configValue={style?.ShowTitleControl?.checked}
          firstOption=""
          secondOption=""
          fullWidth
        />
        <SelectController
          label="Fonte"
          handle={handle}
          kWord="TitleFontFamily"
          options={fontOptions}
          configValue={style?.StyleTitleControl?.fontFamily}
          disabled={!style?.ShowTitleControl?.checked}
        />
        <InputLineController
          label="Tamanho"
          handle={handle}
          kWord="TitleFontSize"
          configValue={style?.StyleTitleControl?.fontSize}
          min={1}
          max={80}
          step={1}
          precision={0}
          isNumber
          disabled={!style?.ShowTitleControl?.checked}
        />
        <ButtonLineController
          handle={handle}
          boldKWord="TitleBold"
          boldVal={style?.StyleTitleControl?.bold}
          italicKWord="TitleItalic"
          italicVal={style?.StyleTitleControl?.italic}
          underlineKWord="TitleUnderline"
          underlineVal={style?.StyleTitleControl?.underline}
          fontColorKWord="TitleFontColor"
          fontColorVal={style?.StyleTitleControl?.color}
          fontColorText="Utilizando tema global"
          fontColorDisabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
          disabled={!style?.ShowTitleControl?.checked}
          fullWidth
        />
      </ControllersGroup>
      {KpiControllers ? (
        <KpiControllers
          handle={handle}
          config={style}
          data={data}
          extend={extend}
          setExtend={setExtend}
          selectedValue={selectedValue}
          selectedLine={selectedLine}
          selectedColumn={selectedColumn}
          selectedFunction={selectedFunction}
          isGlobalThemeActive={isGlobalThemeActive}
        />
      ) : <></>}
    </div>
  ) : <></>
  );
};

CustomSide.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  type: PropTypes.string,
  selectedColumn: PropTypes.objectOf(PropTypes.any),
  selectedValue: PropTypes.objectOf(PropTypes.any),
  selectedLine: PropTypes.objectOf(PropTypes.any),
  selectedFunction: PropTypes.objectOf(PropTypes.any),
  isGlobalThemeActive: PropTypes.bool,
};

CustomSide.defaultProps = {
  config: undefined,
  data: undefined,
  handle: () => {},
  type: '',
  selectedColumn: undefined,
  selectedValue: undefined,
  selectedLine: undefined,
  selectedFunction: undefined,
  isGlobalThemeActive: false,
};

export default CustomSide;
