import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import Loader from '../../juristec-ui/core/Loader';

import { AuthContext } from '../../context/AuthProvider';

const logoutRedirect = () => window.location.replace(`${window.location.protocol}//${window.location.host}/login`);

const PrivateRoute = ({
  component: RouteComponent,
  onlyAdmin,
  onlyScientist,
  onlySuper,
  adminSupport,
  userAttributes,
  companyAttributes,
  ...rest
}) => {
  const {
    currentUser, user, claimsUser, userCompany,
  } = useContext(AuthContext);

  const checkAttributes = () => ((
    userAttributes && !!Object.keys(userAttributes).find((k) => (
      user[k] !== userAttributes[k]
    ))
  ) || (
    companyAttributes && !!Object.keys(companyAttributes).find((k) => (
      userCompany[k] !== companyAttributes[k]
    ))
  ));

  const filterRoute = (routeProps, condition) => {
    if (!currentUser) return logoutRedirect();
    if (!user || !userCompany) return <Loader />;
    if (!condition) return <Redirect to="/home" />;
    if (checkAttributes()) return <Redirect to="/home" />;
    return <RouteComponent {...routeProps} />;
  };

  const filterIsScientist = (routeProps) => (
    filterRoute(routeProps, user?.isScientist || user?.role === 'scientist' || user?.role === 'super')
  );

  const filterIsSuper = (routeProps) => (
    filterRoute(routeProps, user?.isScientist || user?.role === 'super')
  );

  const filterIsAdmin = (routeProps) => (
    filterRoute(routeProps, claimsUser?.is_admin)
  );

  const filterIsAdminOrSupport = (routeProps) => (
    filterRoute(routeProps, claimsUser?.is_admin || claimsUser?.is_support)
  );

  if (onlyAdmin) {
    return (
      <Route
        {...rest}
        render={filterIsAdmin}
      />
    );
  }

  if (onlyScientist) {
    return (
      <Route
        {...rest}
        render={filterIsScientist}
      />
    );
  }

  if (onlySuper) {
    return (
      <Route
        {...rest}
        render={filterIsSuper}
      />
    );
  }

  if (adminSupport) {
    return (
      <Route
        {...rest}
        render={filterIsAdminOrSupport}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => (currentUser ? (
        <RouteComponent {...routeProps} />
      ) : (
        logoutRedirect()
      ))}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  onlyAdmin: PropTypes.bool,
  onlyScientist: PropTypes.bool,
  onlySuper: PropTypes.bool,
  adminSupport: PropTypes.bool,
  userAttributes: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ])),
  companyAttributes: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ])),
};

PrivateRoute.defaultProps = {
  onlyAdmin: false,
  onlyScientist: false,
  onlySuper: false,
  adminSupport: false,
  userAttributes: undefined,
  companyAttributes: undefined,
};

export default PrivateRoute;
