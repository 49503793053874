/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import compare from '../../../utils/functions/sorting';
import { expDateOrder } from '../../utils/formatCharts/orderDatetime';
import { SortArrow } from '../../icons';

import {
  StyledTable, ColumnHeader, IconWrapper, ColumnHeaderLabel,
} from './styled/DataTable.styled';

const sortOrderPicker = {
  ascending: 1,
  descending: -1,
};

const checkTitleType = (val) => (
  typeof val !== 'object' ? val : undefined
);

/**
 * A component for displaying tabulated data sets
 */
const DataTable = ({
  columns,
  rowData,
  rowTotals,
  defaultSortField,
  defaultSortOrder,
  headerColor,
  rowColor,
  strippedRowsColor,
  theadStyle,
  tbodyStyle,
  indexAsHeader,
  expandRow,
  usePagination,
  page,
  itemsPerPage,
  useNumSorting,
  compactView,
  setStateSort,
  toolsPosition,
  ...props
}) => {
  const [dataList, setDataList] = useState(rowData);
  const [sortBy, setSortBy] = useState({
    order: sortOrderPicker[defaultSortOrder],
    field: defaultSortField,
  });

  const sortColumn = (field) => {
    setSortBy({
      order: field === sortBy.field ? -1 * sortBy.order : 1,
      field,
    });
  };

  useEffect(() => {
    setSortBy({
      order: sortOrderPicker[defaultSortOrder],
      field: defaultSortField,
    });
  }, [defaultSortField, defaultSortOrder]);

  useEffect(() => {
    const numSorting = useNumSorting && (columns?.find((c) => c.field === sortBy.field))?.isNumeric;
    const aux = [...rowData].sort(
      (a, b) => sortBy.order * (compare(
        expDateOrder(a[sortBy.field]), expDateOrder(b[sortBy.field]), numSorting,
      )),
    );
    setDataList(
      usePagination ? aux.slice(page * itemsPerPage, (page + 1) * itemsPerPage) : aux,
    );
    if (setStateSort) {
      setStateSort(sortBy);
    }
  }, [sortBy, rowData, page, itemsPerPage]);

  return (
    <StyledTable
      rowColor={rowColor}
      strippedRowsColor={strippedRowsColor}
      indexAsHeader={indexAsHeader}
      headerColor={headerColor}
      expand={expandRow}
      compactView={compactView}
      {...props}
    >
      <thead style={{ ...(theadStyle || {}), ...(compactView ? { fontSize: '0.7rem' } : {}) }}>
        {columns && (
          <tr className="columnHeader" style={compactView ? { display: 'flex' } : {}}>
            {columns.map((column, key) => (
              <th
                key={key}
                title={checkTitleType(column?.label || column?.field)}
                style={{
                  ...(headerColor ? { backgroundColor: headerColor } : {}),
                  ...(compactView
                    ? {
                      flex: '1 1 80px', padding: '4px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0', maxWidth: '10.6rem',
                    }
                    : {}),
                }}
              >
                <ColumnHeader>
                  {toolsPosition === 'start' && column.tools}
                  <ColumnHeaderLabel
                    sortable={column.sortable}
                    compact={compactView}
                    {...(column.sortable && {
                      onClick: () => sortColumn(column.field),
                    })}
                  >
                    {column.label}
                  </ColumnHeaderLabel>
                  {column.sortable && (
                    <IconWrapper toggle={sortBy} field={column.field}>
                      <SortArrow />
                    </IconWrapper>
                  )}
                  {toolsPosition === 'end' && column.tools}
                </ColumnHeader>
              </th>
            ))}
          </tr>
        )}
      </thead>
      <tbody style={{ ...tbodyStyle, ...(compactView ? { fontSize: '0.6rem' } : {}) }}>
        {dataList
          && dataList.map((data, rowKey) => (
            data && (
              <Fragment key={rowKey}>
                <tr
                  className={expandRow && data?.showMoreContent ? 'show-content' : ''}
                  onClick={() => (expandRow
                    && data?.toggleMoreContent
                    ? data.toggleMoreContent(!data?.showMoreContent) : {})}
                  style={compactView ? { display: 'flex' } : {}}
                >
                  {columns.map((column, columnKey) => (column.field === 'index' ? (
                    <th
                      key={columnKey}
                      style={headerColor && indexAsHeader ? { backgroundColor: headerColor } : {}}
                    >
                      {column.valueGetter ? column.valueGetter(data) : data[column.field]}
                    </th>
                  ) : (
                    <td
                      key={columnKey}
                      title={checkTitleType(
                        column.valueGetter ? column.valueGetter(data) : data[column.field],
                      )}
                      style={{
                        ...(column.dataColor ? { backgroundColor: column.dataColor } : {}),
                        ...(compactView
                          ? {
                            flex: '1 1 80px', padding: '4px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0', maxWidth: '10.6rem', cursor: 'pointer', margin: '1px 0px',
                          }
                          : {}),
                      }}
                    >
                      {column.valueGetter ? column.valueGetter(data) : data[column.field]}
                    </td>
                  )))}
                </tr>
                {expandRow && (
                  <tr className={`extra-row ${data?.showMoreContent ? 'show-row' : 'hide-row'}`}>
                    <th>
                      <div aria-label="extra-content" />
                    </th>
                    <td colSpan={columns.length}>
                      {data?.moreContent || <></>}
                    </td>
                  </tr>
                )}
              </Fragment>
            )
          ))}
        {rowTotals && (
          <tr key="totais-row" style={compactView ? { display: 'flex' } : {}}>
            {columns.map((column, columnKey) => (column.field === 'index' ? (
              <th key={`totais-row-index-${columnKey}`} style={headerColor && indexAsHeader ? { backgroundColor: headerColor } : {}}>
                {column.valueGetter ? column.valueGetter(rowTotals) : rowTotals[column.field]}
              </th>
            ) : (
              <td
                key={`totais-row-vals-${columnKey}`}
                title={checkTitleType(
                  column.valueGetter ? column.valueGetter(rowTotals) : rowTotals[column.field],
                )}
                style={{
                  ...(column.dataColor ? { backgroundColor: column.dataColor } : {}),
                  ...(compactView
                    ? {
                      flex: '1 1 80px', padding: '4px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '0', maxWidth: '10.6rem',
                    }
                    : {}),
                }}
              >
                {column.valueGetter ? column.valueGetter(rowTotals) : rowTotals[column.field]}
              </td>
            )))}
          </tr>
        )}
      </tbody>
    </StyledTable>
  );
};

DataTable.propTypes = {
  /**
   * A array of objects with the parameters of each column
   * ex.: [{field: string, label: string, valueGetter: func, sortable: boolean},...]
   */
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * A array of objects with the values of each row
   */
  rowData: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * An object with the totals of the colums in a row
   */
  rowTotals: PropTypes.objectOf(PropTypes.any),
  /**
   * A string that represents which field will be sorted by default
   */
  defaultSortField: PropTypes.string,
  /**
   * A string that represents which order will be sorted by default
   */
  defaultSortOrder: PropTypes.oneOf(['ascending', 'descending'], PropTypes.string),
  /**
   * The background color of the header row. Can be passed theme key names
   */
  headerColor: PropTypes.string,
  /**
   * The background color of each row. Can be passed theme key names
   */
  rowColor: PropTypes.string,
  /**
   * The background color of each odd row. Can be passed theme key names
   */
  strippedRowsColor: PropTypes.string,
  /**
   * Inline style of the table header
   */
  theadStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * Inline style of the table body
   */
  tbodyStyle: PropTypes.objectOf(PropTypes.any),
  /**
   * Indicates whether the index of the rows is part of the header
   */
  indexAsHeader: PropTypes.bool,
  /**
   * Indicates whether the even row is the expansion of the odd row
   */
  expandRow: PropTypes.bool,
  /**
   * Indicates whether the table needs to be paginated
   */
  usePagination: PropTypes.bool,
  /**
   * Indicates the actual page
   */
  page: PropTypes.number,
  /**
   * Indicates how many items (rows) to show in a page
   */
  itemsPerPage: PropTypes.number,
  /**
   * Indicates whether is to use number only sorting.
   */
  useNumSorting: PropTypes.bool,
  /**
   * Indicates whether the view is compact
   */
  compactView: PropTypes.bool,
  /**
   * A function to set the state of the sorting
   */
  setStateSort: PropTypes.func,
  /**
   * Position of the tools
   */
  toolsPosition: PropTypes.oneOf(['start', 'end']),
};

DataTable.defaultProps = {
  defaultSortField: '',
  defaultSortOrder: 'ascending',
  headerColor: undefined,
  rowColor: 'white',
  rowTotals: undefined,
  strippedRowsColor: undefined,
  theadStyle: undefined,
  tbodyStyle: undefined,
  indexAsHeader: false,
  expandRow: false,
  usePagination: false,
  page: 0,
  itemsPerPage: Infinity,
  useNumSorting: false,
  compactView: false,
  setStateSort: undefined,
  toolsPosition: 'end',
};

export default DataTable;
