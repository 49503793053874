/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';

import { Container, Options, UserInfos } from './styled/InstanceUserRow.styled';

import IconButton from '../IconButton';
import Avatar from '../Avatar';
import Tooltip from '../Tooltip';
import {
  ClosedLock,
  OpenLock,
  Edit,
  TransferUser,
  Trash,
  CloneDash,
  Sessions,
  ProfileUser,
  NewFile,
} from '../../icons';

const InstanceUserRow = ({
  user,
  handleBlock,
  handleTransfer,
  handleDelete,
  handlePermission,
  handleCopyDashboards,
  handleMultiSession,
  handleGenEmptyGed,
  userIsAdmin,
}) => {
  const _handleBlock = () => {
    handleBlock(user);
  };

  const _handleTransfer = () => {
    handleTransfer(user);
  };

  const _handleDelete = () => {
    handleDelete(user);
  };

  const _handlePermission = () => {
    handlePermission(user);
  };

  const _handleCopyDashboards = () => {
    handleCopyDashboards(user);
  };

  const _lockMultiSession = () => {
    handleMultiSession(user, 'False');
  };

  const _unlockMultiSession = () => {
    handleMultiSession(user, 'True');
  };

  const _handleEmptyGed = () => {
    handleGenEmptyGed(user);
  };

  return (
    <Container>
      <UserInfos>
        <Avatar name={user.name} />
        <div className="instance-row-user-name-email">
          <span className="name">
            {user.name}
          </span>
          <span className="email">
            {user.email}
          </span>
        </div>
      </UserInfos>
      <Options>
        {userIsAdmin && (
          <Tooltip text={user.disabled ? 'Desbloquear usuário' : 'Bloquear usuário'} atModal>
            {user.disabled
              ? (
                <IconButton variant="pattern" color="primary" onClick={_handleBlock}>
                  <OpenLock />
                </IconButton>
              ) : (
                <IconButton variant="pattern" color="primary" onClick={_handleBlock}>
                  <ClosedLock />
                </IconButton>
              )}
          </Tooltip>
        )}
        {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics' && (
          <Tooltip text="Gerar relatório GED vazio" atModal>
            <IconButton variant="pattern" color="primary" onClick={_handleEmptyGed}>
              <NewFile />
            </IconButton>
          </Tooltip>
        )}
        {userIsAdmin && (
          <>
            {!!handleTransfer && (
              <>
                <Tooltip text="Transferir arquivos e copiar dashboards" atModal>
                  <IconButton variant="pattern" color="primary" onClick={_handleTransfer}>
                    <TransferUser />
                  </IconButton>
                </Tooltip>
                <Tooltip text="Verificar arquivos e dashboards" atModal>
                  <IconButton variant="pattern" color="primary" onClick={_handleCopyDashboards}>
                    <CloneDash />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip text="Alterar permissão" atModal>
              <IconButton variant="pattern" color="primary" onClick={_handlePermission}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip text={user.multiSessions ? 'Bloquear sessão simultânea' : 'Liberar sessão simultânea'} atModal>
              {user.multiSessions
                ? (
                  <IconButton variant="pattern" color="primary" onClick={_lockMultiSession}>
                    <ProfileUser />
                  </IconButton>
                ) : (
                  <IconButton variant="pattern" color="primary" onClick={_unlockMultiSession}>
                    <Sessions />
                  </IconButton>
                )}
            </Tooltip>
            <Tooltip text="Apagar usuário" atModal>
              <IconButton variant="pattern" color="primary" onClick={_handleDelete}>
                <Trash />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Options>
    </Container>
  );
};

InstanceUserRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    disabled: PropTypes.bool,
    multiSessions: PropTypes.bool,
  }),
  handleTransfer: PropTypes.func,
  handleBlock: PropTypes.func,
  handleDelete: PropTypes.func,
  handlePermission: PropTypes.func,
  handleCopyDashboards: PropTypes.func,
  handleMultiSession: PropTypes.func,
  handleGenEmptyGed: PropTypes.func,
  userIsAdmin: PropTypes.bool,
};

InstanceUserRow.defaultProps = {
  user: {},
  handleBlock: () => {},
  handleTransfer: undefined,
  handleDelete: () => {},
  handlePermission: () => {},
  handleCopyDashboards: () => {},
  handleMultiSession: () => {},
  handleGenEmptyGed: () => {},
  userIsAdmin: false,
};

export default InstanceUserRow;
