/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../juristec-ui/core/Tooltip';

import { HashContainer, HashContainerPop } from './styled/GenEmptyGed.styled';

const CopyHash = ({ hash }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const textArea = document.createElement('textarea');
    textArea.textContent = hash;
    textArea.style.position = 'fixed'; /* Prevent scrolling to bottom of page in MS Edge. */
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    navigator.clipboard.writeText(hash);
    setCopied(true);
  };

  const hoverEnter = () => {
    setCopied(false);
  };

  return (
    <HashContainer onMouseEnter={hoverEnter} onClick={copyToClipboard}>
      <div>{hash}</div>
      <Tooltip
        className="hover-tt"
        text={copied ? 'Texto copiado!' : 'Clique para copiar'}
        direction="right"
        atModal
      >
        <HashContainerPop onClick={copyToClipboard}>
          {hash}
        </HashContainerPop>
      </Tooltip>
    </HashContainer>
  );
};

CopyHash.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default CopyHash;
