/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../../juristec-ui/core/DatePicker';
// import Select from '../../juristec-ui/core/Select';
import Select from '../../juristec-ui/core/SelectNew';
import IconButton from '../../juristec-ui/core/IconButton';
import InputTextLine from '../../juristec-ui/core/InputTextLine';

import { trimString } from '../../juristec-ui/utils/functions/formatString';

import { Add, Close } from '../../juristec-ui/icons';

import {
  ItemContainer,
  BadgeContainer,
  ScrollContainer,
  FilterBadge,
  BadgeClose,
} from './styled/KpiFilter.styled';
import 'react-datepicker/dist/react-datepicker.css';

const FilterByValues = ({ options, values, handle }) => (
  <Select
    label="Valores"
    onChange={handle}
    value={values}
    options={options}
    searchable
    selectAll
    sortBy="ascending"
    multiple
    stayOpen
    atModal
    // menuPlacement="auto"
    // maxMenuHeight={150}
    // menuPosition="fixed"
    // formatOptionLabel={false}
    // closeMenuOnSelect={false}
    // fullWidth
  />
);

const FilterByN = ({ values, handle, orientation }) => (
  <ItemContainer orientation={orientation}>
    <InputTextLine
      label="Valor de N"
      value={values[0]?.toString().replace('.', ',')}
      onChange={(e) => handle(trimString(e.target.value))}
    />
  </ItemContainer>
);

const FilterBetweenAB = ({ values, handle, orientation }) => (
  <ItemContainer orientation={orientation}>
    <InputTextLine
      label="Valor de A"
      value={values[0]?.toString().replace('.', ',')}
      onChange={(e) => handle({ start: trimString(e.target.value) })}
    />
    <InputTextLine
      label="Valor de B"
      value={values[1]?.toString().replace('.', ',')}
      onChange={(e) => handle({ end: trimString(e.target.value) })}
    />
  </ItemContainer>
);

const FilterBetweenDates = ({ values, handle, orientation }) => {
  const valA = values[0] instanceof Date ? values[0] : new Date();
  const valB = values[1] instanceof Date ? values[1] : new Date();
  return (
    <ItemContainer orientation={orientation}>
      <DatePicker
        value={valA}
        onChange={(val) => handle({ start: val })}
        label="Valor de A"
        startDate={valA}
        endDate={valB}
        maxDate={valB}
        atModal
      />
      <DatePicker
        value={valB}
        onChange={(val) => handle({ end: val })}
        label="Valor de B"
        startDate={valA}
        endDate={valB}
        minDate={valA}
        atModal
      />
    </ItemContainer>
  );
};

const FilterByNDate = ({ values, handle, orientation }) => (
  <ItemContainer orientation={orientation}>
    <DatePicker
      value={values[0] instanceof Date ? values[0] : new Date()}
      onChange={(val) => handle(val)}
      label="Valor de N"
      atModal
    />
  </ItemContainer>
);

const FilterBySpecificDate = ({ values, handle, orientation }) => (
  <ItemContainer orientation={orientation}>
    <Select
      label="Momento específico"
      options={[
        { value: 'hoje', label: 'Hoje', id: 'today' },
        { value: 'ontem', label: 'Ontem', id: 'yesterday' },
        { value: 'amanhã', label: 'Amanhã', id: 'tomorrow' },
      ]}
      value={values[0]}
      onChange={(val) => handle(val)}
      searchable
    />
  </ItemContainer>

);

const FilterBySearch = ({ values, handle, orientation }) => {
  const [inputVal, setInputVal] = useState('');
  const handleInput = (e) => setInputVal(e.target.value);

  const addVal = () => {
    const val = inputVal.trim();
    if (inputVal.trim().length > 0) {
      handle({ val, operation: 'add' });
      setInputVal('');
    }
  };

  const delVal = (val) => {
    handle({ val, operation: 'delete' });
  };

  const keyHandle = (e) => {
    if (e.key === 'Enter') addVal();
  };

  return (
    <>
      <ItemContainer>
        <InputTextLine
          label="Valor de busca"
          value={inputVal}
          onChange={handleInput}
          onKeyDown={keyHandle}
        />
        <IconButton
          color="secondary"
          shape="rounded"
          variant="contained"
          onClick={addVal}
          style={{ height: '35px', width: '35px', margin: 'auto' }}
        >
          <Add />
        </IconButton>
      </ItemContainer>
      <ScrollContainer orientation={orientation}>
        <BadgeContainer>
          {values.map((val, i) => (
            <FilterBadge
              key={`${val}${i}`}
              color="secondary"
              textTransform="uppercase"
              size="small"
            >
              <span>{val}</span>
              <BadgeClose onClick={() => delVal(val)}>
                <Close />
              </BadgeClose>
            </FilterBadge>
          ))}
        </BadgeContainer>
      </ScrollContainer>
    </>
  );
};

const KpiFilter = ({
  filterType, options, values, handle, format, orientation,
}) => {
  if (filterType !== 'values' && filterType !== 'not-values' && format === 'datetime64[ns]') {
    if (values.length <= 0) return <></>;
    switch (filterType) {
      case 'specific_date':
        return <FilterBySpecificDate values={values} handle={handle} orientation={orientation} />;
      case 'not-after':
      case 'after':
      case 'not-before':
      case 'before':
      case 'not-after_inc':
      case 'after_inc':
      case 'not-before_inc':
      case 'before_inc':
        return <FilterByNDate values={values} handle={handle} orientation={orientation} />;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc':
        return <FilterBetweenDates values={values} handle={handle} orientation={orientation} />;
      default:
        return <FilterByN values={values} handle={handle} orientation={orientation} />;
    }
  } else {
    switch (filterType) {
      case 'not-values':
      case 'values':
        return <FilterByValues options={options} values={values} handle={handle} />;
      case 'not-between':
      case 'between':
      case 'not-between_inc':
      case 'between_inc':
        return <FilterBetweenAB values={values} handle={handle} orientation={orientation} />;
      case 'not-contains':
      case 'contains':
        return <FilterBySearch values={values} handle={handle} orientation={orientation} />;
      default:
        return <FilterByN values={values} handle={handle} orientation={orientation} />;
    }
  }
};

KpiFilter.propTypes = {
  filterType: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  values: PropTypes.arrayOf(PropTypes.any),
  handle: PropTypes.func,
  format: PropTypes.string,
  orientation: PropTypes.string,
};

KpiFilter.defaultProps = {
  filterType: 'values',
  options: [],
  values: [],
  handle: () => {},
  format: '',
  orientation: 'row',
};

export default KpiFilter;
