/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  InputLineController,
  ToggleSwitchController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  SimpleColorPickerController,
} from '../../../controllers/Base';

import fontOptions from '../../../utils/controllersUtils/options';

const LabelControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup title="Rótulos" id="labels" extend={extend} setExtend={setExtend}>
      <ToggleSwitchController
        label="Habilitar rótulos nas barras"
        handle={handle}
        kWord="EnableLabelControl"
        configValue={config?.EnableLabelControl?.checked}
        firstOption=""
        secondOption=""
        fullWidth
      />
      {config.LayoutControl !== 'horizontal' && (
        <ChoiceBoxController
          label="Posição dos rótulos"
          handle={handle}
          kWord="LabelFormatControl"
          options={[
            { value: 'Superior', label: 'Superior', id: 'Superior' },
            { value: 'Central', label: 'Central', id: 'Central' },
          ]}
          configValue={config?.LabelFormatControl}
          fullWidth
          disabled={!config?.EnableLabelControl?.checked}
        />
      )}
      <ChoiceBoxController
        label="Cor"
        handle={handle}
        kWord="LabelTextColorControl2"
        options={[
          { value: 'brighter', label: 'Clara', id: 'brighter' },
          { value: 'darker', label: 'Escura', id: 'darker' },
          { value: 'custom', label: 'Customizada', id: 'custom' },
        ]}
        configValue={config?.LabelTextColorControl?.modifiers?.[0]?.[0] || 'custom'}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      {config?.LabelTextColorControl?.value?.toString() !== '0' && typeof config?.LabelTextColorControl !== 'string' && (
        <RangeController
          label="Opacidade"
          handle={handle}
          kWord="LabelTextOpacityControl"
          configValue={config?.LabelTextColorControl?.modifiers?.[0]?.[1] || '1'}
          min={0}
          max={2}
          step={0.1}
          precision={1}
          fullWidth
          disabled={!config?.EnableLabelControl?.checked}
        />
      )}
      {config?.LabelTextColorControl?.value?.toString() !== '0' && typeof config?.LabelTextColorControl === 'string' && (
        <SimpleColorPickerController
          label="Cor customizada"
          handle={handle}
          kWord="LabelTextColorControl"
          configValue={config?.LabelTextColorControl}
          orientation="row"
          fullWidth
          disabled={!config?.EnableLabelControl?.checked}
        />
      )}
      <RangeController
        label="Rotação"
        handle={handle}
        kWord="LabelRotationControl"
        configValue={config?.LabelRotationControl}
        min={-180}
        max={180}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <RangeController
        label="Posição horizontal"
        handle={handle}
        kWord="LabelTranslateX"
        configValue={config?.LabelTranslateX}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />
      <RangeController
        label="Posição vertical"
        handle={handle}
        kWord="LabelTranslateY"
        configValue={config?.LabelTranslateY}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.EnableLabelControl?.checked}
      />

      <hr style={{ width: '100%', color: 'lightgray', opacity: '0.2' }} />

      <ToggleSwitchController
        label="Habilitar rótulos nos pontos"
        handle={handle}
        kWord="EnableLabelPointsControl"
        configValue={config?.EnableLabelPointsControl}
        firstOption=""
        secondOption=""
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor dos rótulos"
        handle={handle}
        kWord="LabelTextColorPointsControl"
        configValue={config?.LabelTextColorPointsControl}
        orientation="row"
        fullWidth
        disabled={!config?.EnableLabelPointsControl}
      />
      <RangeController
        label="Rotação"
        handle={handle}
        kWord="LabelRotationPointsControl"
        configValue={config?.LabelRotationPointsControl}
        min={-180}
        max={180}
        fullWidth
        disabled={!config?.EnableLabelPointsControl}
      />
      <RangeController
        label="Posição horizontal"
        handle={handle}
        kWord="LabelTranslateXPoints"
        configValue={config?.LabelTranslateXPoints}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.EnableLabelPointsControl}
      />
      <RangeController
        label="Posição vertical"
        handle={handle}
        kWord="LabelTranslateYPoints"
        configValue={config?.LabelTranslateYPoints}
        min={-500}
        max={500}
        fullWidth
        disabled={!config?.EnableLabelPointsControl}
      />

      <hr style={{ width: '100%', color: 'lightgray', opacity: '0.2' }} />

      <SelectController
        label="Fonte"
        handle={handle}
        kWord="LabelsFontFamilyControl"
        options={fontOptions}
        configValue={config?.LabelsFontFamilyControl?.value}
        disabled={!(config?.EnableLabelControl?.checked || config?.EnableLabelPointsControl)}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="LabelsFontSizeControl"
        configValue={config?.LabelsFontSizeControl?.value}
        min={1}
        max={30}
        step={1}
        precision={0}
        isNumber
        disabled={!(config?.EnableLabelControl?.checked || config?.EnableLabelPointsControl)}
      />
      <InputLineController
        label="Prefixo"
        handle={handle}
        kWord="PrefixControl"
        configValue={config?.PrefixControl}
        fullWidth
        disabled={!(config?.EnableLabelControl?.checked || config?.EnableLabelPointsControl)}
      />
      <InputLineController
        label="Sufixo"
        handle={handle}
        kWord="SufixControl"
        configValue={config?.SufixControl}
        fullWidth
        disabled={!(config?.EnableLabelControl?.checked || config?.EnableLabelPointsControl)}
      />
      <ChoiceBoxController
        label="Casas decimais"
        handle={handle}
        kWord="Decimals"
        options={[
          { value: 'auto', label: 'Automático', id: 'auto' },
          { value: 'fixed', label: 'Customizável', id: 'fixed' },
        ]}
        configValue={config?.Decimals || 'auto'}
        fullWidth
        disabled={!(config?.EnableLabelControl?.checked || config?.EnableLabelPointsControl)}
      />
      <RangeController
        label="Quantidade de casas"
        handle={handle}
        kWord="separadorNumericoCasas"
        configValue={config?.separadorNumericoCasas}
        min={0}
        max={5}
        step={1}
        precision={0}
        fullWidth
        disabled={!(config?.EnableLabelControl?.checked || config?.EnableLabelPointsControl) || config?.Decimals !== 'fixed'}
      />
      <SelectController
        label="Moeda"
        handle={handle}
        kWord="LabelCurrencyControl"
        options={['Nenhum', 'R$', 'US$', '€'].map((d) => ({ value: d, label: d, id: d }))}
        configValue={config?.LabelCurrencyControl}
        disabled={!(config?.EnableLabelControl?.checked || config?.EnableLabelPointsControl)}
      />
      <SelectController
        label="Agrupamento"
        handle={handle}
        kWord="LabelGroupingControl"
        options={[
          { value: 'Nenhum', label: 'Nenhum', id: 'Nenhum' },
          { value: 'milhar', label: 'Milhar (M)', id: 'Milhar (M)' },
          { value: 'milhao', label: 'Milhão (Mi)', id: 'Milhão (Mi)' },
          { value: 'bilhao', label: 'Bilhão (Bi)', id: 'Bilhão (Bi)' },
        ]}
        configValue={config?.LabelGroupingControl || 'Nenhum'}
        disabled={!(config?.EnableLabelControl?.checked || config?.EnableLabelPointsControl)}
      />
    </ControllersGroup>
  </>
);

LabelControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

LabelControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default LabelControllers;
