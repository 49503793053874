import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  margin: 0 .5rem;
  max-width: 350px;
  @media ${devices.phoneS} {
    max-width: auto;
  }
`;

export const InfoText = styled.div`
  margin-bottom: 1rem;
`;

export const WarningMessage = styled.span`
  color: ${({ theme }) => theme.error};
`;
