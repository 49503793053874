import React, { useEffect, useContext, useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import { PolicyContext } from '../../context/PolicyProvider';
import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';

import Policy from '../ProgressiveModals/Policy';
import RegisterPhone from '../ProgressiveModals/RegisterPhone';

import PrivateRoute from '../PrivateRoute';
import firebase from '../../utils/firebase';
import { getRefUser } from '../../utils/firestore';
import Multifactor from '../../utils/multifactor';
import handleLoginErrors from '../../utils/firestoreErrors/HandleLoginErrors';
import useRecaptchaV2 from '../../hooks/useRecaptchaV2';

// view pages
import DashboardPage from '../../views/DashboardPage';
import LabelCreatePage from '../../views/LabelCreatePage';
import KpiCreatePage from '../../views/KpiCreatePage';
import KpisPage from '../../views/KpisPage';
import FilesViewerPage from '../../views/FilesViewerPage';
import FilesPage from '../../views/FilesPage';
import UserManagement from '../../views/UserManagement';
import AdminPage from '../../views/AdminPage';
import BigDataPage from '../../views/BigDataPage';
import ProcessPage from '../../views/ProcessPage';
import TemplatesPage from '../../views/TemplatesPage';
import NotFoundFile from './NotFoundFile';
import SwitchHeader from './SwitchHeader';
import Loader from '../../juristec-ui/core/Loader';

function Navigation() {
  const [isPolicyOk, setIsPolicyOk] = useState(false);
  const [isMfaOk, setIsMfaOk] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { state: policyState, policyAPI } = useContext(PolicyContext);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { user, currentUser, userCompany } = useContext(AuthContext);
  const v2Verifier = useRecaptchaV2();

  useEffect(() => {
    (async () => {
      await policyAPI.init();
    })();
  }, []);

  const closeFeedback = async (msg) => {
    setAlertConfig({
      type: 'warning',
      text: 'Ação Necessária',
      child: msg,
      withFunction: true,
      withoutConfirm: true,
      confirmFunction: async () => {
        await firebase.logout();
        closeModal();
      },
    });
  };

  /** Politica de privacidade e termos de uso */
  const handlePolicyModal = async () => {
    const policyData = await policyAPI.getPolicy(policyState.actualPolicyId);

    const closeAlert = () => (
      closeFeedback('Ao não concordar com a política de privacidade e os termos de uso, você não poderá acessar o sistema.')
    );

    const handlePolicyConsent = async () => {
      await getRefUser(currentUser.uid)
        .update({
          acceptedPolicyId: policyState.actualPolicyId,
          acceptedPolicyDate: firebase.serverTimestamp(),
          acceptedPolicyVersion: policyData.policy_version,
        })
        .then(() => {
          closeModal();
          setIsPolicyOk(true);
        })
        .catch((err) => {
          setAlertConfig({ type: 'error', text: `Erro inesperado: ${err}` });
        });
      // window.location.reload();
    };

    setModalConfig({
      title: 'Política de Privacidade e Termos de Uso',
      closeFunc: closeAlert,
      children: (
        <Policy
          policyInfo={policyData}
          submitConsent={() => handlePolicyConsent()}
          hide={closeAlert}
        />
      ),
    });
  };

  const handleAddMfa = () => {
    const closeAlert = () => (
      closeFeedback('Você não poderá acessar o sistema se um número para o multifator não for adicionado.')
    );

    const mfa = new Multifactor(v2Verifier.getVerifier())
      .success(() => {
        setIsLoading(false);
        setIsMfaOk(true);
        closeModal();
      })
      .error((er) => {
        const { text, message } = handleLoginErrors(er);
        setIsLoading(false);
        setAlertConfig({
          type: 'error',
          text,
          child: message,
        });
      });
    setModalConfig({
      title: 'Adicionar multifator',
      closeFunc: closeAlert,
      children: (
        <RegisterPhone
          hide={closeAlert}
          mfa={mfa}
          setLoading={setIsLoading}
        />
      ),
    });
  };

  useEffect(() => {
    if (!policyState.isLoading && policyState.actualPolicyId) {
      if (policyState.actualPolicyId !== user.acceptedPolicyId) {
        handlePolicyModal();
      } else {
        setIsPolicyOk(true);
      }
    }
  }, [policyState.actualPolicyId]);

  useEffect(() => {
    if (userCompany && isPolicyOk) {
      if (userCompany.multifactor && !firebase.getMfaUser().enrolledFactors?.length > 0) {
        handleAddMfa();
      } else {
        setIsMfaOk(true);
      }
    }
  }, [isPolicyOk, userCompany]);

  return (
    <>
      {(isLoading) && <Loader />}
      {isPolicyOk && isMfaOk ? (
        <div>
          <SwitchHeader />
          <Switch>
            <PrivateRoute exact path="/home" component={DashboardPage} />
            <PrivateRoute exact path="/home/:hash" component={KpisPage} />
            <PrivateRoute exact path="/admin" component={AdminPage} adminSupport />
            <PrivateRoute exact path="/arquivos" component={FilesPage} onlyScientist />
            <PrivateRoute exact path="/bigdata" component={BigDataPage} onlyScientist companyAttributes={{ bigData: true }} />
            <PrivateRoute exact path="/bigdata/:hash" component={ProcessPage} onlyScientist companyAttributes={{ bigData: true }} />
            <PrivateRoute exact path="/usuarios" component={UserManagement} onlyScientist />
            <PrivateRoute exact path="/arquivos/:hash" component={FilesViewerPage} onlyScientist />
            <PrivateRoute exact path="/templates" component={TemplatesPage} onlyScientist />
            <PrivateRoute exact path="/label/:hash" component={LabelCreatePage} onlyScientist />
            <PrivateRoute exact path="/kpi/:hash" component={KpiCreatePage} onlyScientist />
            <Route render={() => <NotFoundFile />} />
          </Switch>
        </div>
      ) : (<></>)}
    </>
  );
}

export default Navigation;
