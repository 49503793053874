import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';

// components
import FileViewData from '../../components/FileViewData';
import FileViewDataToolbar from '../../components/Toolbars/FileViewDataToolbar';
import CustomColumns from '../../components/Modals/CustomColumns';
import ManageCustomColumns from '../../components/ProgressiveModals/ManageCustomColumns';
import VirtualAssistant from '../../components/VirtualAssistant';
import Loader from '../../juristec-ui/core/Loader';
import AssistantResponse from '../../components/Modals/AssistantResponse';
import AssistantNotice from '../../components/Modals/AssistantNotice';
import Button from '../../juristec-ui/core/Button';

// contexts
import { FilesContext } from '../../context/FilesProvider';
import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import { UsersContext } from '../../context/UsersProvider';

// others
import urlHash from '../../utils/functions/urlHash';
// import { csvDateFormatOptions, xlsxDateFormatOptions } from '../../options';
// import compare from '../../utils/functions/sorting';
import uuidv4 from '../../juristec-ui/utils/functions/randomUUID';

const legacyTypes = {
  float64: 'float',
  int64: 'float',
  'datetime64[ns]': 'date',
  object: 'abc',
  category: 'abc',
  get(k) {
    return this[k] || k;
  },
};

// const decimalOptions = [
//   { value: '.', label: 'Ponto' },
//   { value: ',', label: 'Vírgula' },
// ];

function FilesViewerPage() {
  const { hash } = useParams();
  const history = useHistory();
  //
  const { state, filesAPI } = useContext(FilesContext);
  const { user, userCompany } = useContext(AuthContext);
  const { setModalConfig, closeModal, toggleModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { state: usersState, usersAPI } = useContext(UsersContext);

  const [file, setFile] = useState({
    origin: 'Desktop',
    filename: '',
    updatedAt: '',
    size: '',
  });
  const [page, setPage] = useState(0);
  const [fileData, setFileData] = useState({ columns: [] });
  const [fileLength, setFileLength] = useState(null);
  //
  const [stateSort, setStateSort] = useState({
    order: 0,
    field: '',
  });

  const originalTypes = useRef({});
  const [columnTypes, setColumnTypes] = useState({});
  const [changedKeys, setChangedKeys] = useState([]);
  const [ownerName, setOwnerName] = useState();
  //
  // const [dateFormat, setDateFormat] = useState({ value: '', label: '' });
  // const [decimalFormat, setDecimalFormat] = useState(decimalOptions[0]);
  //
  // const [isCsv, setIsCsv] = useState(false);
  // check if the user is owner or can write in the file
  const [canManipulate, setCanManipulate] = useState(false);
  // Assistant
  const logsCursor = useRef();
  const [compactView, setCompactView] = useState(false);
  const [showAssistant, setShowAssistant] = useState(false);
  const [assistantMsgs, setAssistantMsgs] = useState(null);

  const getFileInfo = async (fileId) => {
    const { error, msg, res } = await filesAPI.getFile(fileId);
    if (error) {
      setAlertConfig({
        type: 'error',
        text: msg,
      });
      return;
    }
    setFile(res);
    setCanManipulate(user?.uid === res?.owner || res?.shared_write.includes(user?.uid));
    // const isFileCsv = res.filename.split('.').pop().includes('csv');
    // if (isFileCsv) {
    //   setDateFormat(csvDateFormatOptions[0]);
    // } else setDateFormat(xlsxDateFormatOptions[0]);
    // setIsCsv(isFileCsv);
  };

  const goBack = () => {
    history.goBack();
  };

  const handleNoticeModal = async () => {
    const closeFeedback = () => {
      setAlertConfig({
        type: 'warning',
        text: 'Ação Necessária',
        child: 'Ao não concordar você não poderá acessar o serviço do assistente.',
        withFunction: true,
        withoutConfirm: true,
        confirmFunction: closeModal,
      });
    };

    const handleConsent = async () => {
      const { error, msg } = await usersAPI.setUserGpt();
      if (error) {
        setAlertConfig({
          type: 'error',
          text: msg,
        });
        return;
      }
      closeModal();
      setShowAssistant(true);
    };

    setModalConfig({
      title: 'Aviso ao Usuário',
      closeFunc: closeFeedback,
      children: (
        <AssistantNotice
          submitConsent={handleConsent}
          hide={closeFeedback}
        />
      ),
    });
  };

  const closeAssistant = () => setShowAssistant(false);
  const openAssistant = () => {
    if (!user.acceptedGpt) {
      handleNoticeModal();
    } else {
      setShowAssistant(true);
    }
  };

  const handleDfModal = (df, type) => {
    setModalConfig({
      title: type === 'kpi' ? 'KPI\'s' : 'Tabela',
      yesLabel: 'Ok',
      children: (
        <AssistantResponse
          isKpi={type === 'kpi'}
          dataframe={df}
        />
      ),
    });
  };

  const genResponseMsg = (msgObj) => {
    let msgTxt = msgObj.message;
    if (msgObj.type === 'kpi' || msgObj.type === 'dataframe') {
      const showDf = () => handleDfModal(msgObj.message, msgObj.type);
      msgTxt = (
        <Button
          onClick={showDf}
          variant="pattern"
          color="info"
          size="small"
          textTransform="none"
        >
          {msgObj.type === 'kpi' ? 'Resposta em KPI\'s' : 'Resposta em Tabela'}
        </Button>
      );
    }
    return {
      id: msgObj.id || uuidv4(),
      type: msgObj.error ? 'error' : msgObj.type,
      message: msgTxt,
      timestamp: msgObj.timestamp || new Date(),
    };
  };

  const fetchPage = async () => {
    // const {
    //   error,
    //   msg,
    //   res,
    //   raw,
    // } = await filesAPI.getFileSample(file.file_id, false, 20, page + 1);
    const pageSize = compactView ? 40 : 20;
    const [fileRes, assistRes] = await Promise.all([
      filesAPI.getFileSample(
        file.file_id, false, pageSize, page + 1, stateSort.field, stateSort.order,
      ),
      filesAPI.loadAssistantLogs(file.file_id),
    ]);
    if (fileRes.error) {
      setAlertConfig({
        type: 'error',
        text: fileRes.msg,
        child: fileRes.raw,
      });
      goBack();
      return;
    }
    if (assistRes.error) {
      setAlertConfig({
        type: 'error',
        text: assistRes.msg,
        child: assistRes.raw,
      });
      return;
    }

    const fileLen = (fileRes.res.total_pages - 1) * pageSize;
    const types = Object.keys(fileRes.res.types).reduce((acc, cur) => {
      acc[cur] = legacyTypes.get(fileRes.res.types[cur]);
      return acc;
    }, {});

    setFileData(fileRes.res);
    setFileLength(fileLen);
    setColumnTypes(types);
    setChangedKeys([]);
    originalTypes.current = types;
    setAssistantMsgs(assistRes.logs.map((l) => genResponseMsg(l)));
    logsCursor.current = assistRes.cursor;
  };

  useEffect(() => {
    if (file.filename) fetchPage();
  }, [page, file, stateSort.field, stateSort.order, compactView]);

  useEffect(() => {
    if (file.owner) {
      const userOwner = usersState.users.filter((u) => u.uid === file.owner);
      setOwnerName(userOwner[0]?.name || userOwner[0]?.email.split('@')[0] || '');
    }
  }, [file]);

  useEffect(() => {
    const getParams = () => {
      const str = urlHash(hash, true).split('/');
      return str[0];
    };

    const fileId = getParams();
    getFileInfo(fileId);
    const params = new URLSearchParams(window.location.search);
    if (params.has('assistant')) openAssistant();
  }, []);

  const handleOptimizer = async () => {
    const submitF = async () => {
      const {
        error: editError,
        msg: editMsg,
        raw,
      } = await filesAPI.editColumns(file.file_id, columnTypes);
      if (editError) {
        setAlertConfig({
          type: 'error',
          text: editMsg,
          child: raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: (
          <span>
            Arquivo
            {' '}
            <strong style={{ fontStyle: 'italic' }}>
              {file.filename}
            </strong>
            {' '}
            validado com sucesso!
          </span>
        ),
      });
      fetchPage();
    };

    setAlertConfig({
      text: 'Deseja Revalidar o arquivo?',
      type: 'warning',
      withFunction: true,
      withoutConfirm: true,
      confirmFunction: submitF,
    });
  };

  const columnTypeChange = (field, type) => {
    if (originalTypes?.current[field] === type) {
      setChangedKeys((o) => o.filter((k) => k !== field));
    } else {
      setChangedKeys((o) => [...o, field]);
    }
    setColumnTypes((p) => ({ ...p, [field]: type }));
  };

  const openManageCustomColumns = async (fileId, {
    columns, custom, editCColumn, submit,
  }) => {
    const genPreview = async (newColumns) => {
      const res = await filesAPI.previewCustomColumns(fileId, newColumns);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: res.raw,
        });
        return null;
      }
      return res.res;
    };
    const hide = () => toggleModal('manage-custom-columns');
    setModalConfig({
      title: 'Colunas Customizadas',
      nodeTarget: 'manage-custom-columns',
      children: (
        <ManageCustomColumns
          columns={columns}
          custom={custom}
          editCustom={editCColumn}
          genPreview={genPreview}
          submit={submit}
          hide={hide}
        />
      ),
    });
  };

  const openCustomColumns = async () => {
    const hide = () => toggleModal('list-custom-columns');

    const submit = async (newColumns) => {
      if (newColumns.length > 0) {
        const res = await filesAPI.manageCustomColumns(file.file_id, newColumns);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
            child: res.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: `Relatório ${file.filename} alterado com sucesso!`,
        });
        fetchPage();
        hide();
      }
    };

    const handleDecision = (newColumns) => {
      setAlertConfig({
        type: 'warning',
        text: 'Tem certeza que deseja salvar as alterações?',
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: () => submit(newColumns),
      });
    };

    const fileColumns = fileData.columns.reduce((aux, val) => {
      // eslint-disable-next-line no-param-reassign
      if (val !== 'index') aux[val] = legacyTypes.get(fileData.types[val]);
      return aux;
    }, {});
    const handleManageCustomColumns = (info) => openManageCustomColumns(file.file_id, info);

    setModalConfig({
      title: 'Colunas Customizadas',
      nodeTarget: 'list-custom-columns',
      children: (
        <CustomColumns
          columns={fileColumns}
          custom={fileData.custom_columns ?? []}
          manageCustomColumns={handleManageCustomColumns}
          hide={hide}
          submit={handleDecision}
        />
      ),
    });
  };

  const handleVirtualAssistant = async (query) => {
    const res = await filesAPI.callVirtualAssistant(file.file_id, query, 'chat');
    return genResponseMsg(res);
  };

  const handleLoadMoreLogs = async () => {
    if (logsCursor.current) {
      const assistRes = await filesAPI.loadAssistantLogs(file.file_id, logsCursor.current);
      if (assistRes.error) {
        setAlertConfig({
          type: 'error',
          text: assistRes.msg,
          child: assistRes.raw,
        });
        return;
      }
      logsCursor.current = assistRes.cursor;
      setAssistantMsgs((o) => [...assistRes.logs.map((l) => genResponseMsg(l)), ...o]);
    }
  };

  return (
    <>
      {(state.isLoading || fileLength === null) && <Loader />}
      <FileViewDataToolbar
        changed={changedKeys?.length > 0}
        ownerName={ownerName}
        fileName={file?.filename}
        fileSize={file?.size}
        lastUpdate={file?.updated_at}
        handleOptimizer={handleOptimizer}
        handleCustomColumns={openCustomColumns}
        openVirtualAssistant={userCompany?.gptApi ? openAssistant : null}
        goBack={goBack}
        compactView={compactView}
        setCompactView={setCompactView}
      />
      <FileViewData
        fileData={fileData}
        fileLength={fileLength}
        canManipulate={canManipulate}
        columnTypes={columnTypes}
        columnTypeChange={columnTypeChange}
        fetchPage={fetchPage}
        page={page}
        origin={file.origin || 'Desktop'}
        setPage={setPage}
        compactView={compactView}
        setStateSort={setStateSort}
        //
        // dateFormat={dateFormat}
        // setDateFormat={setDateFormat}
        // decimalFormat={decimalFormat}
        // setDecimalFormat={setDecimalFormat}
        // isCsv={isCsv}
      />
      {userCompany?.gptApi && (
        <VirtualAssistant
          isOpen={showAssistant}
          close={closeAssistant}
          messagesState={[assistantMsgs, setAssistantMsgs]}
          submitQuestion={handleVirtualAssistant}
          loadMoreLogs={handleLoadMoreLogs}
          columns={fileData.types || {}}
          handleDfModal={handleDfModal}
        />
      )}
    </>
  );
}

export default FilesViewerPage;
