/* eslint-disable react/prop-types */
import React, {
  useState, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';
import Avatar from '../../juristec-ui/core/Avatar';
import IconButton from '../../juristec-ui/core/IconButton';
import {
  Bell,
  Dashboard,
  ArchiveBox,
  UploadImage,
  Tutorial,
  TemplateUse,
} from '../../juristec-ui/icons';
import Burger from '../../juristec-ui/core/Burger';
import Backdrop from '../../juristec-ui/core/Backdrop';
import Drawer from '../../juristec-ui/core/Drawer';
import Tooltip from '../../juristec-ui/core/Tooltip';
import ProfileConfig from '../../juristec-ui/core/ProfileConfig';
import Popover from '../../juristec-ui/core/Popover';
import Notifications from '../Popovers/Notifications';
import TutorialGuide from './TutorialGuide';
import ProfileEdit from '../ProfileEdit';

import useDeviceType from '../../juristec-ui/hooks/useDeviceType';
import { ModalContext } from '../../context/ModalProvider';
import { TourContext } from '../../context/TourProvider';

import {
  HeaderContainer,
  Brand,
  DrawerNavLink,
  NavLink,
  LeftElementHeader,
  RightElementHeader,
  HeaderAvatar,
  NotificationElement,
  CompanyLogoOverlay,
  SeparatorLine,
} from './styled/Header.styled';

/**
 * The Header component is the Navigation bar of the project.
 * That is where the user can find easy access to other routes and main features of the application.
 */
const support = () => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
    case 'legalone-analytics':
      window.open('https://suporte.legalone-analytics.com.br/hc/pt-br', '_blank');
      break;

    case 'benner-metrics':
      window.open('https://suporte.bennermetrics.com.br/hc/pt-br', '_blank');
      break;

    default:
      window.open('https://suporte.legalmetrics.com.br/hc/pt-br', '_blank');
      break;
  }
};

const Header = ({
  user,
  userInfo,
  userMultifactors,
  userIsAdmin,
  userIsSupport,
  clientLogo,
  handleProfileChanges,
  handleAvatarChange,
  nightMode,
  setNightMode,
  handleLogout,
  handleLogoChange,
  checkImage,
  newMfaNumber,
  delMfaNumber,
  moreNotifs,
  notifsStates,
  showNotifs,
  handleNotifsPop,
  bigDataAccess,
}) => {
  const device = useDeviceType();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [showProfileConfig, setShowProfileConfig] = useState(false);
  const [showGuideDrawer, setShowGuideDrawer] = useState(false);

  const theme = useTheme();

  const { setModalConfig, toggleModal } = useContext(ModalContext);

  const inputFile = useRef(null);

  const { tourOpen, nextStep /* refreshTour */ } = useContext(TourContext);

  const toggleState = (stateSetter) => {
    stateSetter((prev) => !prev);
  };

  const openProfileEdit = () => {
    setShowProfileConfig(false);
    setModalConfig({
      title: 'Perfil de usuário',
      children: (
        <ProfileEdit
          user={{
            name: user.displayName,
            src: user.photoURL || '',
            email: user.email,
            permission: userInfo.role || 'guest',
            mfas: userMultifactors() || [],
          }}
          addMfaNumber={newMfaNumber}
          removeMfaNumber={delMfaNumber}
          closeModal={toggleModal}
          submitFormData={handleProfileChanges}
          submitAvatarImg={handleAvatarChange}
          checkImage={checkImage}
        />
      ),
      disableFocus: true,
    });
  };

  const toggleNotifications = () => handleNotifsPop((p) => !p);
  const closeNotifications = () => handleNotifsPop(false);
  const closeSideMenu = () => setShowSideMenu(false);

  /* useEffect(() => {
    const script = document.createElement('script');
    script.src = (() => {
      switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
        case 'legalone-analytics':
          return 'https://static.zdassets.com/ekr/snippet.js?key=f5e10f81-26e6-484c-a8b8-07479806e908';

        case 'benner-metrics':
          return 'https://static.zdassets.com/ekr/snippet.js?key=2b669cc5-f213-44bc-b2a9-03633a522925';

        case 'legalmetrics':
          return 'https://static.zdassets.com/ekr/snippet.js?key=2245de47-cba2-42b2-ab5e-9eef62aa14c6';

        default:
          return 'https://static.zdassets.com/ekr/snippet.js?key=2245de47-cba2-42b2-ab5e-9eef62aa14c6';
      }
    })();
    script.id = 'ze-snippet';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []); */

  return (
    <>
      {showSideMenu && <Backdrop onClick={closeSideMenu} />}
      <Drawer
        open={showSideMenu}
        direction="left"
        handleCloseDrawer={closeSideMenu}
      >
        <img
          src={clientLogo}
          alt="logo"
          width="150px"
          style={{ alignSelf: 'center', padding: '1rem 0 0 0' }}
        />
        <SeparatorLine />
        <DrawerNavLink to="/home" onClick={closeSideMenu} activeClassName="header-active-link">
          <Dashboard />
          Dashboards
        </DrawerNavLink>
        {userInfo?.role !== 'guest' && (
          <>
            <DrawerNavLink to="/arquivos" onClick={closeSideMenu} activeClassName="header-active-link">
              <ArchiveBox />
              Arquivos
            </DrawerNavLink>
            <DrawerNavLink to="/templates" onClick={closeSideMenu} activeClassName="header-active-link">
              <TemplateUse />
              Templates
            </DrawerNavLink>
          </>
        )}
        <DrawerNavLink to="/bigdata" onClick={() => setShowSideMenu(false)} activeClassName="header-active-link">
          <Dashboard />
          Big Data
        </DrawerNavLink>
      </Drawer>
      <HeaderContainer>
        <LeftElementHeader>
          <Burger
            open={showSideMenu}
            color="primary"
            onClick={() => toggleState(setShowSideMenu)}
          />
          <NavLink to="/home">
            <Brand>{theme.logo}</Brand>
          </NavLink>
          <NavLink to="/home" activeClassName="header-active-link">
            Dashboards
          </NavLink>
          {userInfo?.role !== 'guest' && (
            <>
              <NavLink to="/arquivos" activeClassName="header-active-link">
                Arquivos
              </NavLink>
              <NavLink to="/templates" activeClassName="header-active-link">
                Templates
              </NavLink>
            </>
          )}
          {userInfo?.role !== 'guest' && bigDataAccess && (
            <NavLink to="/bigdata" activeClassName="header-active-link">
              Big Data
            </NavLink>
          )}
        </LeftElementHeader>
        <RightElementHeader>
          {device === 'phone' ? null : (
            <Tooltip text="Tutorial" direction="left" className="tooltip-round">
              <IconButton onClick={() => toggleState(setShowGuideDrawer)}>
                <Tutorial />
              </IconButton>
            </Tooltip>
          )}
          <div style={{ position: 'relative' }}>
            <Popover
              style={{
                borderRadius: '20px',
                backgroundColor: theme.popoverBackground,
                overflow: 'hidden',
              }}
              open={showNotifs}
              stayOpened
              closePopover={closeNotifications}
            >
              <Popover.Action>
                <Tooltip text="Notificações" direction="left" className="tooltip-round">
                  <>
                    {notifsStates.notificationsCount > 0 && (
                      <NotificationElement newMessages={notifsStates.notificationsCount}>
                        {notifsStates.notificationsCount > 99 ? ';D' : notifsStates.notificationsCount}
                      </NotificationElement>
                    )}
                    <IconButton onClick={toggleNotifications}>
                      <Bell />
                    </IconButton>
                  </>
                </Tooltip>
              </Popover.Action>
              <Popover.Content direction="bottom-end">
                <Notifications
                  closePopover={closeNotifications}
                  userInfo={userInfo}
                  moreNotifications={moreNotifs}
                  notificationsLoading={notifsStates.isLoading}
                  notificationList={notifsStates.notifications}
                  haveMore={!!notifsStates.lastElement}
                />
              </Popover.Content>
            </Popover>
          </div>
          <Popover
            style={{
              borderRadius: '20px',
              backgroundColor: theme.popoverBackground,
              overflow: 'hidden',
            }}
            open={showProfileConfig}
            stayOpened
            closePopover={() => setShowProfileConfig(false)}
          >
            <Popover.Action>
              <HeaderAvatar>
                <img src={clientLogo} alt="client_logo" />
                <Tooltip text="Configurações" direction="left">
                  <Avatar
                    name={user.displayName}
                    src={user.photoURL}
                    size="medium"
                    style={{
                      borderColor: `${theme.grey}`,
                      color: `${theme.primary}`,
                    }}
                    onClick={() => { toggleState(setShowProfileConfig); if (tourOpen) nextStep(); }}
                    className="profile_popover"
                  />
                </Tooltip>
                {userInfo?.role === 'super' && (
                  <CompanyLogoOverlay>
                    <Tooltip text="Alterar logo" direction="left" atModal>
                      <IconButton
                        color="#fff"
                        size={device === 'phone' ? 'small' : 'medium'}
                        onClick={() => inputFile.current.click()}
                      >
                        <UploadImage />
                      </IconButton>
                    </Tooltip>
                    <input
                      type="file"
                      ref={inputFile}
                      accept="image/*"
                      onChange={handleLogoChange}
                      style={{ display: 'none' }}
                    />
                  </CompanyLogoOverlay>
                )}
              </HeaderAvatar>
            </Popover.Action>
            <Popover.Content direction="bottom-end">
              <ProfileConfig
                openProfileEdit={openProfileEdit}
                closeProfileConfig={() => setShowProfileConfig(false)}
                user={{
                  name: user.displayName,
                  src: user.photoURL,
                  isAdmin: userIsAdmin,
                  isSupport: userIsSupport,
                  role: userInfo?.role,
                }}
                nightMode={nightMode}
                handleNightMode={setNightMode}
                handleLogout={handleLogout}
                tourContext={{ tourOpen, nextStep }}
              />
            </Popover.Content>
          </Popover>
        </RightElementHeader>
      </HeaderContainer>
      <Drawer
        open={showGuideDrawer}
        direction="right"
        handleCloseDrawer={() => setShowGuideDrawer(false)}
      >
        <TutorialGuide
          support={support}
          close={() => setShowGuideDrawer(false)}
        />
      </Drawer>
    </>
  );
};

Header.propTypes = {
  /**
   * A string to the path of the client logo
   */
  clientLogo: PropTypes.string,
  checkImage: PropTypes.func,
};

Header.defaultProps = {
  clientLogo: '',
  checkImage: () => {},
};

export default Header;
