import React from 'react';
import { customValueAxis, staticPercent } from './handleCustomValue';

const breakTextIntoNLines = (text, n) => {
  const words = text.toString().split(' ');
  const meanSize = Math.floor(text.toString().length / n);
  const arr = [];
  let acc = 0;
  let subtext = [];
  let count = 0;
  words.forEach((w) => {
    count += 1;
    if (count !== words.length) {
      acc += w.length;
      subtext.push(w);
      if (acc >= meanSize) {
        arr.push(subtext.join(' '));
        acc = 0;
        subtext = [];
      }
    } else {
      subtext.push(w);
      arr.push(subtext.join(' '));
    }
  });
  return arr;
};

const customAxisTick = (dt, config, useFormat, axisType) => (
  <g key={`${axisType}-${dt.value}`} transform={`translate(${dt.x}, ${dt.y})`}>
    <line
      x1="0"
      y1="0"
      x2={dt.lineX}
      y2={dt.lineY}
      style={{ stroke: '#777', strokeWidth: '1px' }}
    />
    <text
      dominantBaseline={dt.textBaseline}
      textAnchor={dt.textAnchor}
      transform={`translate(${dt.textX}, ${dt.textY}) rotate(${dt.rotate})`}
      style={{
        fontSize: config?.AxisGlobal?.fontSize,
        fontFamily: `'${config?.AxisGlobal?.fontFamily}', Arial`,
        fill: config?.AxisGlobal?.color,
        outlineWidth: '0px',
        outlineColor: 'transparent',
      }}
    >
      {breakTextIntoNLines(
        useFormat ? customValueAxis(dt.value, config, axisType) : dt.value,
        config?.EnableAxisX?.maxLabelLines || 1,
      ).map((e, i) => (
        <tspan key={e} x="0" dy={`${1.2 * i}em`}>
          {e}
        </tspan>
      ))}
    </text>
  </g>
);

const customAxis = (AxisPos, config, useFormat = true) => {
  switch (AxisPos) {
    case 'top':
      return config?.EnableAxisX?.axisTop ? {
        ...config?.EnableAxisX,
        legendOffset: config?.EnableAxisX?.legendOffset * -1,
        renderTick: (dt) => customAxisTick(dt, config, useFormat, 'X'),
        format: (value) => (useFormat ? customValueAxis(value, config, 'X') : value),
      } : null;
    case 'bottom':
      return config?.EnableAxisX?.axisBot ? {
        ...config?.EnableAxisX,
        renderTick: (dt) => customAxisTick(dt, config, useFormat, 'X'),
        format: (value) => (useFormat ? customValueAxis(value, config, 'X') : value),
      } : null;
    case 'right':
      return config?.EnableAxisY?.axisRight ? {
        ...config?.EnableAxisY,
        legendOffset: config?.EnableAxisY?.legendOffset * -1,
        renderTick: (dt) => customAxisTick(dt, config, useFormat, 'Y'),
        format: (value) => (useFormat ? customValueAxis(value, config, 'Y') : value),
      } : null;
    case 'left':
      return config?.EnableAxisY?.axisLeft ? {
        ...config?.EnableAxisY,
        renderTick: (dt) => customAxisTick(dt, config, useFormat, 'Y'),
        format: (value) => (useFormat ? customValueAxis(value, config, 'Y') : value),
      } : null;
    default:
      break;
  }
  return null;
};

export const customPercentAxis = (config, total) => {
  if (config?.EnableAxisY?.axisRight) {
    return {
      ...config?.EnableAxisY,
      legendOffset: config?.EnableAxisY?.legendOffset * -1,
      format: (value) => (
        staticPercent(value / total, config?.EnableAxisY?.Decimals, config?.DecimalsYPercent)
      ),
    };
  } return null;
};

export default customAxis;
