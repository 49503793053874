/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../../juristec-ui/core/InputTextLine';
import InputNumberLine from '../../../../juristec-ui/core/InputNumberLine';
import Select from '../../../../juristec-ui/core/Select';
import Toggle from '../../../../juristec-ui/core/Toggle';
import DatePicker from '../../../../juristec-ui/core/DatePicker/DatePicker';

import {
  planOpt, operationalStatusOpt,
} from '../../../../options';
import getPlan from '../../../../plans';

import { trimString, compactString } from '../../../../juristec-ui/utils/functions/formatString';

import {
  verifyInput,
  verifyLink,
} from '../../../../juristec-ui/utils/validators/inputTextValidators';

import {
  MainContainer,
  SideBySideContainer,
  ColumnContainer,
  InputWrapper,
  VerticalSeparator,
  CategoryLabel,
} from './styled/NewInstance.styled';

const FirstStep = ({
  companyNameState,
  companyUrlState,
  planState,
  externalIdentifierState,
  createL1ReportState,
  operationalStatusState,
  dashboardsState,
  publicLinkState,
  multifactorState,
  hasPushState,
  volumeDataState,
  scientistLimitState,
  adminLimitState,
  guestLimitState,
  snapsLimitState,
  freeMonitoringState,
  monitoringPriceState,
  newProcessPriceState,
  oldProcessPriceState,
  bigDataState,
  gptApiState,
  signatureDateState,
  operationalDateState,
  projectValueState,
  installationPriceState,
  connectIntegrationState,
  reportAmountState,
  consultingState,
  parameterizedDashState,
  customDashState,
  attemptsPerHourState,
  isEdit,
}) => {
  // Estados que contem o que sera enviado (declarados no componente pai)
  const [companyName, setCompanyName] = companyNameState;
  const [companyUrl, setCompanyUrl] = companyUrlState;
  const [selectedPlan, setSelectedPlan] = planState;
  const [externalIdentifier, setExternalIdentifier] = externalIdentifierState;
  const [createL1Report, setCreateL1Report] = createL1ReportState;
  const [operationalStatus, setOperationalStatus] = operationalStatusState;
  const [, setDashboardLimit] = dashboardsState;
  const [publicLink, setPublicLink] = publicLinkState;
  const [multifactor, setMultifactor] = multifactorState;
  const [hasPush, setHasPush] = hasPushState;
  const [volumeData, setVolumeData] = volumeDataState;
  const [scientistLimit, setScientistLimit] = scientistLimitState;
  const [adminLimit, setAdminLimit] = adminLimitState;
  const [guestLimit, setGuestLimit] = guestLimitState;
  const [snapsLimit, setSnapsLimit] = snapsLimitState;
  const [freeMonitoring, setFreeMonitoringLimit] = freeMonitoringState;
  const [monitoringPrice, setMonitoringPrice] = monitoringPriceState;
  const [newProcessPrice, setNewProcessPrice] = newProcessPriceState;
  const [oldProcessPrice, setOldProcessPrice] = oldProcessPriceState;
  const [bigData, setBigData] = bigDataState;
  const [gptApi, setGptApi] = gptApiState;
  const [signatureDate, setSignatureDate] = signatureDateState;
  const [operationalDate, setOperationalDate] = operationalDateState;
  const [projectValue, setProjectValue] = projectValueState;
  const [installationPrice, setInstallationPrice] = installationPriceState;
  const [connectIntegration, setConnectIntegration] = connectIntegrationState;
  const [reportAmount, setReportAmount] = reportAmountState;
  const [consulting, setConsulting] = consultingState;
  const [parameterizedDash, setParameterizedDash] = parameterizedDashState;
  const [customDash, setCustomDash] = customDashState;
  const [attemptsPerHour, setAttemptsPerHour] = attemptsPerHourState;
  /**
   * Altera outros estados com as configuracoes padroes do plano selecionado
   * @param {string} selectVal Valor que representa o plano selecionado
   */
  const setDefaultPlanConfig = (selectVal) => {
    const tmpPlan = getPlan(selectVal);
    setVolumeData(tmpPlan.diskSize);
    setDashboardLimit(tmpPlan.dashboards);
    setScientistLimit(tmpPlan.scientists);
    setAdminLimit(tmpPlan.supers);
    setGuestLimit(tmpPlan.guests);
    setSnapsLimit(tmpPlan.snapshots);
    setFreeMonitoringLimit(tmpPlan.monitoring || 0);
    setMonitoringPrice(tmpPlan.newMonitoring || 0);
    setNewProcessPrice(tmpPlan.newProcess || 0);
    setOldProcessPrice(tmpPlan.oldProcess || 0);
  };

  // Marca um plano e status operacional inicial, quando os mesmos estiverem vazios
  useEffect(() => {
    if (!selectedPlan.value) {
      setSelectedPlan(planOpt[0]);
      setDefaultPlanConfig(planOpt[0].value);
    }
    if (!operationalStatus.value) {
      setOperationalStatus(operationalStatusOpt[1]);
    }
  }, []);

  /**
   * Lida com a selecao de plano no select, alterando outros estados necessarios
   * @param {object} select Objeto com a opcao escolhida
   */
  const handlePlanSelect = (select) => {
    setSelectedPlan(select);
    if (select.value === 'push') {
      setHasPush(true);
    }
    setDefaultPlanConfig(select.value);
  };

  const handleOperationalStatus = (select) => {
    setOperationalStatus(select);
  };

  /**
   * Lida com o input de nome da empresa, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const companyNameHandle = (e) => {
    const val = e.target.value;
    const msg = verifyInput(val, true, 5);
    setCompanyName({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  /**
   * Lida com o input de link da empresa, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const handleCompanyUrl = (e) => {
    const val = e.target.value;
    const msg = verifyLink(val);
    setCompanyUrl({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };
  /**
   * Lida com o input de identificador externo, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const handleExternalIdentifier = (e) => {
    const val = e.target.value;
    setExternalIdentifier({
      value: val,
      error: false,
      errorMsg: '',
    });
  };
  /**
   * Lida com o input sistema connect, verificando a validade e atualiza o estado informado
   * @param {event} e Evento de alteracao do input
   */
  const handleConnectIntegration = (e) => {
    const val = e.target.value;
    setConnectIntegration({
      value: val,
      error: false,
      errorMsg: '',
    });
  };

  /**
   * Formata o valor do input, a partir do estado informado
   * @param {function} setter Estado a ser atualizado
   * @param {boolean} compact Informa se a formatacao deve remover espacos em branco ou nao
   */
  const handleBlurTrim = (setter, compact = false) => {
    setter((old) => ({
      ...old,
      value: compact ? compactString(old.value) : trimString(old.value),
    }));
  };

  /**
   * Formata o input de nome da empresa quando ele perde o foco
   */
  const companyNameTrim = () => { handleBlurTrim(setCompanyName); };

  /**
   * Formata o input de link da empresa quando ele perde o foco
   */
  const CompanyUrlTrim = () => { handleBlurTrim(setCompanyUrl, true); };

  /**
   * Ativa ou desativa a permissao de compartilhar link publico dos dashboards
   */
  const togglePublicLink = () => { setPublicLink((p) => !p); };

  /**
   * Ativa ou desativa a ativação da autenticação de multifator para os usuários da instância
   */
  const toggleMultifactor = () => { setMultifactor((p) => !p); };

  /**
   * Ativa ou desativa a consultoria do contrato
   */
  const toggleConsulting = () => { setConsulting((p) => !p); };

  /**
   * Ativa ou desativa a opção de criação de relatório do
   * Legal One pelo modelo antigo (Mail ou API GED)
   */
  const toggleL1Report = () => { setCreateL1Report((p) => !p); };

  /**
   * Ativa ou desativa o acesso ao big data para os usuários da instância
   */
  const toggleBigData = () => { setBigData((p) => !p); };

  const toggleGptApi = () => { setGptApi((p) => !p); };

  let project = 'Metrics';
  if (process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics') {
    project = 'Analytics';
  } else if (process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalmetrics') {
    project = 'Metrics';
  } else {
    project = 'Benner-Metrics';
  }

  return (
    <>
      <MainContainer style={{ height: 'calc(100vh - 500px)' }}>
        {/* Formulario a esquerda */}
        <ColumnContainer>
          <InputWrapper>
            <InputTextLine
              label="Nome da empresa"
              onChange={companyNameHandle}
              onBlur={companyNameTrim}
              value={companyName.value}
              error={companyName.errorMsg.length > 0}
              errorMessage={companyName.errorMsg}
            />
          </InputWrapper>
          <InputWrapper>
            <InputTextLine
              label="Url da empresa"
              onChange={handleCompanyUrl}
              onBlur={CompanyUrlTrim}
              value={companyUrl.value}
              error={companyUrl.errorMsg.length > 0}
              errorMessage={companyUrl.errorMsg}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Plano</span>
            <Select
              // selectLabel="Plano"
              placeholder="Selecione um plano"
              placement="start"
              onChange={handlePlanSelect}
              formatOptionLabel={false}
              options={isEdit ? [
                ...planOpt,
                { label: 'Starter (legado)', value: 'standard', id: 'standard' },
              ] : planOpt}
              value={selectedPlan}
              fullWidth
              fullHeight
              atModal
              style={{ margin: 0 }}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Situação Operacional</span>
            <Select
              // selectLabel="Situação Operacional"
              placeholder="Selecione a situação"
              placement="start"
              onChange={handleOperationalStatus}
              formatOptionLabel={false}
              options={operationalStatusOpt}
              value={operationalStatus}
              fullWidth
              fullHeight
              atModal
              style={{ margin: 0 }}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Identificador externo</span>
            <InputTextLine
              // label="Identificador externo"
              onChange={handleExternalIdentifier}
              value={externalIdentifier.value}
              error={externalIdentifier.error}
              errorMessage={externalIdentifier.errorMsg}
            />
          </InputWrapper>
          {/* Configurações do plano */}
          <CategoryLabel>Configurações do plano</CategoryLabel>
          <InputWrapper className="inline">
            <span className="custom-label">Espaço em disco</span>
            <InputNumberLine
              value={volumeData}
              onChange={(e) => setVolumeData(e.target.value)}
              label=""
              setValue={setVolumeData}
              min={1}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Nº de cientistas</span>
            <InputNumberLine
              value={scientistLimit}
              onChange={(e) => setScientistLimit(e.target.value)}
              label=""
              setValue={setScientistLimit}
              min={0}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Nº de proprietários</span>
            <InputNumberLine
              value={adminLimit}
              onChange={(e) => setAdminLimit(e.target.value)}
              label=""
              setValue={setAdminLimit}
              min={1}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Nº de convidados</span>
            <InputNumberLine
              value={guestLimit}
              onChange={(e) => setGuestLimit(e.target.value)}
              label=""
              setValue={setGuestLimit}
              min={0}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Nº de snapshots (stories)</span>
            <InputNumberLine
              value={snapsLimit}
              onChange={(e) => setSnapsLimit(e.target.value)}
              label=""
              setValue={setSnapsLimit}
              min={0}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
        </ColumnContainer>

        <VerticalSeparator />

        {/* Formulario a direita */}
        <ColumnContainer>
          <CategoryLabel>Configurações do contrato</CategoryLabel>
          <InputWrapper className="inline">
            <span className="custom-label">Data da assinatura</span>
            <DatePicker
              value={signatureDate}
              onChange={setSignatureDate}
              label=""
              style={{ width: '50%' }}
              readOnly={false}
              dateFormat="dd/MM/yyyy"
              atModal
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Início da Operação</span>
            <DatePicker
              value={operationalDate}
              onChange={setOperationalDate}
              label=""
              style={{ width: '50%' }}
              readOnly={false}
              dateFormat="dd/MM/yyyy"
              atModal
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">{`Valor ${project}`}</span>
            <InputNumberLine
              value={projectValue}
              onChange={(e) => setProjectValue(e.target.value)}
              label=""
              setValue={setProjectValue}
              min={0}
              numContainerStyle={{ width: '50%' }}
              isMoney
              precision={2}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Valor de implantação</span>
            <InputNumberLine
              value={installationPrice}
              onChange={(e) => setInstallationPrice(e.target.value)}
              label=""
              setValue={setInstallationPrice}
              min={0}
              numContainerStyle={{ width: '50%' }}
              isMoney
              precision={2}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Integração (connect)</span>
            <InputTextLine
              onChange={handleConnectIntegration}
              value={connectIntegration.value}
              error={connectIntegration.error}
              errorMessage={connectIntegration.errorMsg}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Quantidade de relatórios</span>
            <InputNumberLine
              value={reportAmount}
              onChange={(e) => setReportAmount(e.target.value)}
              label=""
              setValue={setReportAmount}
              min={0}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
          <InputWrapper className="toggle">
            <span className="custom-label">Consultoria</span>
            <Toggle
              checked={consulting}
              handleChange={toggleConsulting}
              firstColor="primary"
              size="small"
              disabled={selectedPlan.value === 'push'}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Dashboards Parametrizados</span>
            <InputNumberLine
              value={parameterizedDash}
              onChange={(e) => setParameterizedDash(e.target.value)}
              label=""
              setValue={setParameterizedDash}
              min={0}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Dashboards Personalizados</span>
            <InputNumberLine
              value={customDash}
              onChange={(e) => setCustomDash(e.target.value)}
              label=""
              setValue={setCustomDash}
              min={0}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
          <InputWrapper className="inline">
            <span className="custom-label">Tentativas de atualização de relatório por hora</span>
            <InputNumberLine
              value={attemptsPerHour}
              onChange={(e) => setAttemptsPerHour(e.target.value)}
              label=""
              setValue={setAttemptsPerHour}
              min={3}
              numContainerStyle={{ width: '50%' }}
            />
          </InputWrapper>
          {/* {hasPush && (
            <>
              {/* <InputWrapper className="inline">
                <span className="custom-label">Nº de monitoramentos</span>
                <InputNumberLine
                  value={freeMonitoring}
                  onChange={(e) => setFreeMonitoringLimit(e.target.value)}
                  label=""
                  setValue={setFreeMonitoringLimit}
                  min={0}
                  numContainerStyle={{ width: '50%' }}
                />
              </InputWrapper>
              <InputWrapper className="inline">
                <span className="custom-label">Valor monitoramento adicional</span>
                <InputNumberLine
                  value={monitoringPrice}
                  onChange={(e) => setMonitoringPrice(e.target.value)}
                  label=""
                  setValue={setMonitoringPrice}
                  min={0}
                  precision={2}
                  step={1.00}
                  isMoney
                  numContainerStyle={{ width: '50%' }}
                />
              </InputWrapper>
              <InputWrapper className="inline">
                <span className="custom-label">Valor por processo novo</span>
                <InputNumberLine
                  value={newProcessPrice}
                  onChange={(e) => setNewProcessPrice(e.target.value)}
                  label=""
                  setValue={setNewProcessPrice}
                  min={0}
                  precision={2}
                  step={0.1}
                  isMoney
                  numContainerStyle={{ width: '50%' }}
                />
              </InputWrapper>
              <InputWrapper className="inline">
                <span className="custom-label">Valor por processo legado</span>
                <InputNumberLine
                  value={oldProcessPrice}
                  onChange={(e) => setOldProcessPrice(e.target.value)}
                  label=""
                  setValue={setOldProcessPrice}
                  min={0}
                  precision={2}
                  step={0.1}
                  isMoney
                  numContainerStyle={{ width: '50%' }}
                />
              </InputWrapper>
            </>
          )} */}
        </ColumnContainer>
      </MainContainer>
      <SideBySideContainer>
        <div style={{ marginTop: 'auto' }}>
          <InputWrapper className="toggle">
            <span className="custom-label">Multifator obrigatório</span>
            <Toggle
              checked={multifactor}
              handleChange={toggleMultifactor}
              firstColor="primary"
              size="small"
            />
          </InputWrapper>
          <InputWrapper className="toggle">
            <span className="custom-label">Permitir link público</span>
            <Toggle
              checked={publicLink}
              handleChange={togglePublicLink}
              firstColor="primary"
              size="small"
            />
          </InputWrapper>
          {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics' && (
            <InputWrapper className="toggle">
              <span className="custom-label">Permitir criação de relatório Legal One</span>
              <Toggle
                checked={createL1Report}
                handleChange={toggleL1Report}
                firstColor="primary"
                size="small"
              />
            </InputWrapper>
          )}
        </div>
        <div style={{ marginTop: 'auto' }}>
          <InputWrapper className="toggle">
            <span className="custom-label">Acesso ao Big Data</span>
            <Toggle
              checked={bigData}
              handleChange={toggleBigData}
              firstColor="primary"
              size="small"
            />
          </InputWrapper>
          {process.env.REACT_APP_FIREBASE_PROJECT_LABEL !== 'legalone-analytics' && (
            <InputWrapper className="toggle">
              <span className="custom-label">Assistente Virtual</span>
              <Toggle
                checked={gptApi}
                handleChange={toggleGptApi}
                firstColor="primary"
                size="small"
              />
            </InputWrapper>
          )}
        </div>
      </SideBySideContainer>
    </>
  );
};

FirstStep.propTypes = {
  companyNameState: PropTypes.arrayOf(PropTypes.any).isRequired,
  companyUrlState: PropTypes.arrayOf(PropTypes.any).isRequired,
  planState: PropTypes.arrayOf(PropTypes.any).isRequired,
  externalIdentifierState: PropTypes.arrayOf(PropTypes.any).isRequired,
  createL1ReportState: PropTypes.arrayOf(PropTypes.any).isRequired,
  operationalStatusState: PropTypes.arrayOf(PropTypes.shape).isRequired,
  dashboardsState: PropTypes.arrayOf(PropTypes.any).isRequired,
  publicLinkState: PropTypes.arrayOf(PropTypes.any).isRequired,
  multifactorState: PropTypes.arrayOf(PropTypes.any).isRequired,
  hasPushState: PropTypes.arrayOf(PropTypes.any).isRequired,
  volumeDataState: PropTypes.arrayOf(PropTypes.any).isRequired,
  scientistLimitState: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminLimitState: PropTypes.arrayOf(PropTypes.any).isRequired,
  guestLimitState: PropTypes.arrayOf(PropTypes.any).isRequired,
  snapsLimitState: PropTypes.arrayOf(PropTypes.any).isRequired,
  freeMonitoringState: PropTypes.arrayOf(PropTypes.any).isRequired,
  monitoringPriceState: PropTypes.arrayOf(PropTypes.any).isRequired,
  newProcessPriceState: PropTypes.arrayOf(PropTypes.any).isRequired,
  oldProcessPriceState: PropTypes.arrayOf(PropTypes.any).isRequired,
  bigDataState: PropTypes.arrayOf(PropTypes.any).isRequired,
  gptApiState: PropTypes.arrayOf(PropTypes.any).isRequired,
  signatureDateState: PropTypes.arrayOf(PropTypes.any).isRequired,
  operationalDateState: PropTypes.arrayOf(PropTypes.any).isRequired,
  projectValueState: PropTypes.arrayOf(PropTypes.any).isRequired,
  installationPriceState: PropTypes.arrayOf(PropTypes.any).isRequired,
  connectIntegrationState: PropTypes.arrayOf(PropTypes.any).isRequired,
  reportAmountState: PropTypes.arrayOf(PropTypes.any).isRequired,
  consultingState: PropTypes.arrayOf(PropTypes.any).isRequired,
  parameterizedDashState: PropTypes.arrayOf(PropTypes.any).isRequired,
  customDashState: PropTypes.arrayOf(PropTypes.any).isRequired,
  attemptsPerHourState: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default FirstStep;
