import styled from 'styled-components';
import { devices } from '../../../../juristec-ui/core/breakpoints';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  margin: 0 .5rem;
  max-width: 350px;
  @media ${devices.phoneS} {
    max-width: auto;
  }
`;

export const HashContainerPop = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.copyTextHover};
  border-radius: 5px;
  max-width: max-content;
  width: max-content;
  overflow: visible;
  align-items: center;
  justify-content: center;
  padding: 5px;
  
  cursor: pointer;
  & svg {
    display: initial;
  }
`;

export const HashContainer = styled.div`
  position: relative;
  margin: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  z-index: 0;
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    cursor: pointer;
  }
  & .hover-tt {
    position: absolute;
    z-index: 100;
    max-width: max-content;
  }
  & svg {
    display: none;
    fill: ${({ theme }) => theme.grey};
    position: absolute;
    top: 0;
    & > path {
        fill: ${({ theme }) => theme.grey};
    }
  }
  &:hover {
    ${HashContainerPop} {
      display: flex;
      max-width: max-content;
    }
  }
`;
