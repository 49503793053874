/* eslint-disable react/forbid-prop-types */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ExpandDiagonally,
  Compact,
} from '../../../juristec-ui/icons';
import FilePaginationContainer from '../../FilePaginationContainer';
import DataTable from '../../../juristec-ui/core/DataTable';
import { DataTableContainer, ButtonIconWrapper } from './styled/DataFrameModal.styled';
import Button from '../../../juristec-ui/core/Button';

const DataFrameModal = ({
  dataFrame, fileLength, fetchFileSampleData, theme,
}) => {
  const [compactView, setCompactView] = useState(false);
  const [page, setPage] = useState(0);
  const [stateSort, setStateSort] = useState({
    order: 0,
    field: '',
  });
  const [dataTable, setDatatable] = useState(dataFrame);

  useEffect(() => {
    const fetchData = async () => {
      const pageSize = compactView ? 40 : 20;
      const { newDT } = await fetchFileSampleData(page, stateSort, pageSize);
      setDatatable(newDT || dataFrame);
    };
    fetchData();
  }, [page, stateSort.field, stateSort.order, compactView]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          shape="rounded"
          onClick={() => setCompactView(!compactView)}
        >
          <ButtonIconWrapper>
            {compactView
              ? <ExpandDiagonally width={16} height={16} fill={theme.white} />
              : <Compact width={16} height={16} fill={theme.white} />}
            <span style={{ marginLeft: '8px' }}>
              {compactView ? 'Visão normal' : 'Visão compacta'}
            </span>
          </ButtonIconWrapper>
        </Button>
      </div>
      <DataTableContainer>
        <DataTable
          columns={dataTable?.columns || []}
          rowData={dataTable?.rowData || []}
          headerColor="transparent"
          theadStyle={{
            position: 'sticky',
            top: '-5px',
            zIndex: 1,
            backgroundColor: theme.modal,
          }}
          style={{ width: 'max-content' }}
          compactView={compactView}
          setStateSort={setStateSort}
        />
      </DataTableContainer>
      <FilePaginationContainer
        page={page}
        setPage={setPage}
        totalPages={Math.floor(fileLength / (compactView ? 40 : 20))}
      />
    </>
  );
};

DataFrameModal.propTypes = {
  dataFrame: PropTypes.shape({
    columns: PropTypes.object,
    rowData: PropTypes.arrayOf(PropTypes.array),
  }).isRequired,
  fileLength: PropTypes.number.isRequired,
  fetchFileSampleData: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    modal: PropTypes.string,
    white: PropTypes.string,
  }).isRequired,
};

export default DataFrameModal;
