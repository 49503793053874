import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Avatar from '../Avatar';
import Toggle from '../Toggle';
import {
  Exit, Admin, Users, Moon,
} from '../../icons';

import {
  MainContainer,
  ProfileContainer,
  ProfileInfo,
  Icon,
  Item,
  SeparatorLine,
} from './styled/ProfileConfig.styled';

/**
* A component that contains settings related to the profile
*/
const ProfileConfig = ({
  openProfileEdit,
  closeProfileConfig,
  user,
  nightMode,
  handleNightMode,
  handleLogout,
  tourContext = { tourOpen: false },
}) => {
  const history = useHistory();

  return (
    <MainContainer>
      <ProfileContainer onClick={openProfileEdit}>
        <Avatar name={user.name} src={user.src} size="large" />
        <ProfileInfo>
          <h2>{user.name}</h2>
          <span>
            Editar perfil
          </span>
        </ProfileInfo>
      </ProfileContainer>

      <SeparatorLine />
      <Item onClick={handleNightMode}>
        <Icon><Moon /></Icon>
        <h3 style={{ marginRight: '10px' }}>
          Modo noturno
        </h3>
        <Toggle size="small" checked={nightMode} handleChange={handleNightMode} />
      </Item>

      <SeparatorLine />
      {(user.isAdmin || user.isSupport) && (
        <Item onClick={() => { history.push('/admin'); closeProfileConfig(); }}>
          <Icon><Admin /></Icon>
          {user.isSupport ? (
            <h3>Suporte</h3>
          ) : (
            <h3>Administração</h3>
          )}
        </Item>
      )}
      {user.role !== 'guest' && (
        <Item onClick={() => { history.push('/usuarios'); closeProfileConfig(); if (tourContext.tourOpen) tourContext.nextStep(); }} className="gerenciar_instacia">
          <Icon><Users /></Icon>
          <h3>Gerenciar Instância</h3>
        </Item>
      )}
      <Item onClick={() => handleLogout()}>
        <Icon><Exit /></Icon>
        <h3>Sair</h3>
      </Item>
    </MainContainer>
  );
};

ProfileConfig.propTypes = {
  /**
  * A function to open the profile editing component
  */
  openProfileEdit: PropTypes.func.isRequired,
  /**
  * Function to close de ProfileConfig popover
  */
  closeProfileConfig: PropTypes.func.isRequired,
  /**
  * User profile data (only the name and the picture is used)
  */
  user: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    isAdmin: PropTypes.bool,
    isSupport: PropTypes.bool,
    role: PropTypes.string,
  }).isRequired,
  /**
  * A boolean state that indicates whether is to use night mode theme or not
  */
  nightMode: PropTypes.bool,
  /**
  * A function to perform some action when the style state changes
  */
  handleNightMode: PropTypes.func.isRequired,
  /**
  * A function to logout the current user
  */
  handleLogout: PropTypes.func.isRequired,
};

ProfileConfig.defaultProps = {
  nightMode: false,
};

export default ProfileConfig;
