/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from '../../charts';
import InsightCard from '../../insight';
import IframeCard from '../../iframe';
import TablePagination from '../../../core/TablePagination';

import filterByValues from '../../utils/formatCharts/filterByValues';
import groupByValues from '../../utils/formatCharts/groupByValues';

import useOnScreen from '../../../hooks/useOnScreen';
// import checkDataSize from '../../utils/chartTools/kpiMsg';
import KpiStatus, { KPILIMIT } from '../../utils/chartTools/KpiStatus';

import {
  StyledCardKpi,
  StyledCardKpiTitle,
  StyledCardKpiContent,
  PaginationWrapper,
} from './styled/CardKpi.styled';
import Button from '../../../core/Button';

const getValueBackground = (isValue, globalTheme, background) => {
  if (isValue && globalTheme?.useGlobalTheme) return { backgroundColor: globalTheme.valueBg };
  return { backgroundColor: isValue ? background : 'transparent' };
};

const titleStyle = (tStyle, globalTheme) => ({
  fontSize: `${tStyle?.fontSize ?? 20}px`,
  color: globalTheme?.useGlobalTheme ? globalTheme.titles : (tStyle?.color ?? '#000000'),
  fontFamily: `'${tStyle?.fontFamily ?? 'arial'}', Arial`,
  fontWeight: tStyle?.bold ? 'bold' : 'normal',
  textDecoration: tStyle?.underline ? 'underline' : 'none',
  fontStyle: tStyle?.italic ? 'italic' : 'normal',
});

const LIMITEDKPIS = ['Bar', 'Pie', 'Line', 'Radar', 'Funnel', 'RadialBar', 'Pareto'];
const NOHISTORYKPIS = ['SubsetTable', 'plotly'];
const isLimitedKpi = (kpiType) => LIMITEDKPIS.includes(kpiType);
const isKpiInvalidInStory = (kpiType) => NOHISTORYKPIS.includes(kpiType);

const CardKpi = React.forwardRef(({
  item,
  handleStyleConfig,
  stateDashboardLayout,
  editPainel,
  id,
  showToPrint,
  isDataLoading,
  globalTheme,
  pageState,
  collection,
  autoValueModifiers,
  handleDrilldownClick,
  drilldownSelected,
  removeDrilldown,
}, ref) => {
  const [page, setPage] = pageState;

  // Retorna se o kpi esta visivel na tela ou nao
  const isVisible = useOnScreen(ref);

  function modifyData(filterList, groupList, data, lineName) {
    let newData = data;
    if (filterList?.length > 0) {
      newData = filterByValues(filterList, newData, lineName);
    }
    if (groupList?.length > 0) {
      newData = groupByValues(groupList, newData, lineName);
    }
    return newData;
  }

  const data = useMemo(() => (
    item?.data?.data?.length > 0 ? (
      modifyData(
        item?.meta?.filterValues,
        item?.meta?.groupValues,
        item.data,
        item?.meta?.lines?.[0]?.column,
      )
    ) : (
      { index: [], data: [], columns: [] }
    )
  ), [item]);

  const handleAutoModifier = (modifType, ftype) => {
    autoValueModifiers(
      item, modifType, ftype, data?.columns?.length > KPILIMIT, data?.index?.length > KPILIMIT,
    );
  };

  return (
    <StyledCardKpi
      ref={ref}
      titleFontSize={
        item?.style?.ShowTitleControl?.checked ? item?.style?.StyleTitleControl?.fontSize : 0
      }
      kpiType={item?.style?.type ?? item?.type}
      showTitle={item?.style?.ShowTitleControl?.checked}
      id={id}
    >
      {['label', 'smartLabel'].includes(item?.type) && (
        <>
          <InsightCard
            item={item}
            globalTheme={globalTheme}
          />
        </>
      )}
      {item?.type === 'Iframe' && (
        <KpiStatus
          conditions={new Map([
            [
              'invalidInStory',
              data && isKpiInvalidInStory(item?.linkType) && collection !== 'kpis',
            ],
          ])}
        >
          <IframeCard item={item} />
        </KpiStatus>
      )}
      {!['label', 'smartLabel', 'Iframe'].includes(item?.type) && (
        <>
          {item?.style?.ShowTitleControl?.checked ? (
            <StyledCardKpiTitle
              style={getValueBackground((item?.style?.type ?? item?.type) === 'Value', globalTheme, item?.style?.GlobalValue?.background)}
            >
              <span
                style={titleStyle(item?.style?.StyleTitleControl, globalTheme)}
              >
                {item.name ?? ''}
              </span>
            </StyledCardKpiTitle>
          ) : <></>}
          <StyledCardKpiContent type={item?.type}>
            <KpiStatus
              isLoading={!(showToPrint || isVisible) || isDataLoading}
              conditions={new Map([
                [
                  'invalidInStory',
                  data && isKpiInvalidInStory(item?.type) && collection !== 'kpis',
                ],
                [
                  'emptyDataSet',
                  !data?.data?.length > 0 && item?.type !== 'Value',
                ],
                [
                  'tooMuchCategories',
                  isLimitedKpi(item?.type) && (
                    data?.columns?.length > KPILIMIT || data?.index?.length > KPILIMIT
                  ),
                ],
              ])}
              autoValueModifiers={
                isLimitedKpi(item?.type) && Boolean(autoValueModifiers) ? handleAutoModifier : null
              }
            >
              <>
                {/* ========== Drilldown Back ========== */}
                {drilldownSelected && editPainel && (
                  <div style={{ marginLeft: '1rem' }}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={removeDrilldown}
                    >
                      <span style={{ fontSize: '12px' }}>
                        Voltar
                      </span>
                    </Button>
                  </div>
                )}
                <Chart
                  id="svg"
                  type={item?.type}
                  handleDrilldownClick={handleDrilldownClick}
                  data={data}
                  valueFunction={item?.meta?.values?.[0]?.map}
                  styleConfig={item?.style}
                  kpiSize={stateDashboardLayout?.lg?.filter((d) => d?.i === item?.id)}
                  handleStyleConfig={(param1, param2) => handleStyleConfig(param2, param1)}
                  isEdit={editPainel}
                  globalTheme={globalTheme}
                />
              </>
            </KpiStatus>
          </StyledCardKpiContent>
          {item?.type === 'SubsetTable' && (
            <PaginationWrapper bgColor={globalTheme?.backgroundColor ?? '#ffffff'}>
              <TablePagination
                page={page}
                totalPages={(data?.total_pages ?? 1) - 1}
                setPage={setPage}
                useGoToPage={false}
                label=""
                className="tableWrapperPagination"
                disabled={editPainel}
              />
            </PaginationWrapper>
          )}
        </>
      )}
    </StyledCardKpi>
  );
});

CardKpi.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleStyleConfig: PropTypes.func,
  editPainel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  id: PropTypes.string,
  showToPrint: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  stateDashboardLayout: PropTypes.objectOf(PropTypes.any),
  globalTheme: PropTypes.objectOf(PropTypes.any),
  pageState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  ),
  collection: PropTypes.string,
  autoValueModifiers: PropTypes.func,
  handleDrilldownClick: PropTypes.func,
  drilldownSelected: PropTypes.bool.isRequired,
  removeDrilldown: PropTypes.func.isRequired,
};

CardKpi.defaultProps = {
  id: undefined,
  stateDashboardLayout: undefined,
  handleStyleConfig: undefined,
  showToPrint: false,
  isDataLoading: false,
  globalTheme: { useGlobalTheme: false },
  pageState: [0, () => {}],
  collection: 'kpis',
  autoValueModifiers: null,
  handleDrilldownClick: null,
};

export default CardKpi;
