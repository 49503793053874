import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

import { compactString } from '../../../juristec-ui/utils/functions/formatString';
import { verifyFileName } from '../../../juristec-ui/utils/validators/inputTextValidators';

import { MainContainer } from './styled/GenEmptyGed.styled';

const GenEmptyGed = ({ close, submit }) => {
  const [name, setName] = useState({ value: '', error: true, errorMsg: '' });

  const handleName = (val) => {
    const errMsg = verifyFileName(val);
    setName({
      value: val,
      error: errMsg.length !== 0,
      errorMsg: errMsg,
    });
  };

  const handleNameEvent = (e) => {
    const val = e.target.value.trim();
    handleName(val);
  };

  const nameTrim = () => {
    handleName(compactString(name.value));
  };

  const handleSubmit = () => {
    if (!name.error && name.value.length > 0) {
      submit(name.value);
    }
  };

  return (
    <MainContainer>
      <InputTextLine
        label="Nome do relatório"
        errorMessage={name.errorMsg}
        error={name.errorMsg.length > 0}
        onChange={handleNameEvent}
        value={name.value}
        onBlur={nameTrim}
      />
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={close}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={name.error}
        >
          Gerar
        </Button>
      </ActionsGroup>
    </MainContainer>
  );
};

GenEmptyGed.propTypes = {
  close: PropTypes.func,
  submit: PropTypes.func,
};

GenEmptyGed.defaultProps = {
  close: () => {},
  submit: () => {},
};

export default GenEmptyGed;
