/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Bar from './Bar';
import Pie from './Pie';
import Line from './Line';
import Value from './Value';
import Radar from './Radar';
import Map from './Map';
import Funnel from './Funnel';
import RadialBar from './RadialBar';
import Table from './Table';
import Calendar from './Calendar';
import Waffle from './Waffle';
import BoxPlot from './BoxPlot';
import ScatterPlot from './ScatterPlot';
import SubsetTable from './SubsetTable';
import Pareto from './Pareto';

import getDefaultStyle from '../utils/chartTools/getDefaultStyle';

const chartGallery = {
  Bar,
  Pie,
  Line,
  Value,
  Radar,
  Map,
  Funnel,
  RadialBar,
  Table,
  Calendar,
  Waffle,
  BoxPlot,
  ScatterPlot,
  SubsetTable,
  Pareto,
};

function getChart(type) {
  return chartGallery[type] || null;
}

const Chart = ({
  type,
  data,
  styleConfig,
  kpiSize,
  handleStyleConfig,
  isEdit,
  valueFunction,
  globalTheme,
  handleDrilldownClick,
}) => {
  const Component = getChart(styleConfig?.type || type);
  const style = useMemo(() => (
    { ...getDefaultStyle(styleConfig?.type || type), ...styleConfig }
  ), [styleConfig, type]);
  return (Component) ? (
    <Component
      data={data}
      styleConfig={style}
      kpiSize={kpiSize}
      handleStyleConfig={handleStyleConfig}
      handleDrilldownClick={handleDrilldownClick}
      isEdit={isEdit}
      valueFunction={valueFunction}
      globalTheme={globalTheme}
    />
  ) : null;
};

export default Chart;
